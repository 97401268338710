import { useEffect, useState } from "react";
import RightSideDetails from "../global/rightSideDetails";
import { getFnbMenuCategory, getFnbMenuItemByCategoryID, getFnbMenuSubCategory, getFnbMenuSubCategoryByCategoryID } from "../api/fnbAPI";
import defaultItemImg from '../../assets/images/item.png'
import Select from 'react-select';
import swal from 'sweetalert2';
import RateModal from "./rateModal";

const RateMaster = (props) => {

    const [isInit,               setIsInit]               = useState(true);
    const [rightSideDetails,     setRightSideDetails]     = useState(false);
    const [allCategories,        setAllCategories]        = useState([]);
    const [allSubcategories,     setAllSubcategories]     = useState([]);
    const [subcategories,        setSubcategories]        = useState([]);
    const [allItems,             setAllItems]             = useState([]);
    const [type,                 setType]                 = useState({'CategoryID': 0});
    const [subType,              setSubType]              = useState({'SubCategoryID':0});
    const [itemDetails,          setItemDetails]          = useState({});

    useEffect(() => {
        props.title("Rate Master", "rate-master")
        fetchMenuCategory();
        fetchSubcategoryByMenuD();
    }, []);

    useEffect(() => {  
        rightSideDetails ? Config.Core.OpenDrawer() : setItemDetails({});
    }, [rightSideDetails]);
    
    useEffect(() => {
        if(type?.CategoryID || type?.CategoryID == 0){
            const filteredArr = type?.CategoryID == 0 ? allSubcategories : [{ID: 0, Name: "All"}, ...allSubcategories.filter(f => f.CategoryID === type.CategoryID)];
            setSubcategories(filteredArr);
            setSubType({'SubCategoryID':0});
        }
    }, [type]);
    
    useEffect(() => {
        setSubcategories(allSubcategories);
    }, [allSubcategories]);

    useEffect(() => {
        if((type?.CategoryID || type?.CategoryID == 0) && (subType?.SubCategoryID || subType?.SubCategoryID == 0))
            fetchMenuItemByMenuD(type.CategoryID);
    }, [type, subType]);

    useEffect(() => {       
        if (!isInit) fillDataTable(subType?.SubCategoryID);
    }, [allItems]);

    useEffect(() => {
        if(subType?.SubCategoryID && !isInit){
            $('#tblRateMaster').KTDatatable().destroy(); 
            fillDataTable(subType?.SubCategoryID);
        }
    },[subType]);
    
    function fillDataTable(subCatID) {
        const dataTable = $('#tblRateMaster').KTDatatable({
            data: {
                saveState: false,
                source: subCatID == 0 ? allItems :  allItems.filter(f => f.SubcategoryID == subCatID),
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'Name',
                    title: 'Name',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                    <div class="kt-user-card-v2__pic">
                                    ${(row.Image && row.Image != '') ? `<img src="${row.Image}" alt="user-profile" height="50" width="50">` : `<img src="${defaultItemImg}" alt="user-profile" height="50" width="50">`}
                                </div>
                                    <div class="kt-user-card-v2__details text-clip">
                                    <span class="kt-user-card-v2__name text-clip"> ${row.Name}  </span> <span class="kt-user-card-v2__desc text-clip"> ${row.Code} </span>
                                    </div>
                               </div>`
                    }
                },
                {
                    field: 'Active',
                    title: 'Active',
                    template: function (row) {
                        return row.Active == 'N' ? '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10"> No </span>' : '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                    },
                    width: 50,
                },
            ],
            rows: {
                autoHide: false,
            }
        });

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblRateMaster .kt-datatable__table').addClass('overflow-x-auto');

        dataTable.on('click', 'tr', function () {
            const index  = ($("tr").index(this) - 1);
            if (dataTable.dataSet && dataTable.dataSet.length > 0 && dataTable.dataSet[index]) {
                setItemDetails(dataTable.dataSet[index]);  
                setRightSideDetails(true);           
            } 
        })

    };

    const fetchMenuCategory = async() => {
        try {
            const master = await getFnbMenuCategory();
            setAllCategories([{ID: 0, Name: "All"}, ...master]);
          
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    };

    const fetchSubcategoryByMenuD = async() => {
        try {
            const master = await getFnbMenuSubCategory()
            setAllSubcategories([{ID: 0, Name: "All"}, ...master]);
            
        } catch (err) {
            swal.fire({ icon :'error', titleText : err.message })

        }
    };

    const fetchMenuItemByMenuD = async(id) => {
        try {
            swal.fire({
                titleText: 'Loading',
                text: 'Please Wait...',
                showConfirmButton: false,
                onOpen: () => {
                    swal.showLoading();
                }
            });
            const items = await getFnbMenuItemByCategoryID(id)
            isInit ? setIsInit(false) : $('#tblRateMaster').KTDatatable().destroy(); 
            setAllItems(items);
            swal.close();
        } catch (err) {
            swal.fire({ icon :'error', titleText : err.message })

        }
    };

    const updateTypeDetails = (field, value) => { 
        // setAllSubcategories([{ID: 0, Name: "All"}]);
        setType((d) => {
            return { ...d, [field]: value };
        });
    };

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);        
    };

    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-b0" id="kt_content" style={{paddingTop:' 0px'}}>
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet">
                        <div className="kt-portlet__body padding-t30 padding-b0">
                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" style={{borderBottom: '1px solid #ebedf2'}} role="form">
                                <div className="row">
                                    <label className="col-form-label col-sm-2 text-right  ">Category</label>
                                    <div className="form-group col-sm-2 ">
                                        <div>
                                            <Select 
                                                id="ddlMenuCategory" 
                                                name="ddlMenuCategory"
                                                className="multi-custom"
                                                value={(allCategories && allCategories.length > 0 && (type?.CategoryID  || type?.CategoryID == 0)) && {label: allCategories.find((m) => m.ID == type.CategoryID).Name ,value: type.CategoryID} || ''}
                                                onChange={(event) => updateTypeDetails('CategoryID', event.value)}
                                                options={allCategories.map(c => ({
                                                    label: c.Name,
                                                    value: c.ID
                                                }))}
                                            />
                                        </div>
                                    </div>
                                    <label className="col-form-label col-sm-2 text-right">Subcategory</label>
                                    <div className="form-group col-sm-2">
                                        <div>
                                            <Select 
                                                id="ddlMenuSubCategory" 
                                                name="ddlMenuSubCategory"
                                                className="multi-custom width-95p"
                                                value={((subcategories && subcategories.length > 0 && (subType?.SubCategoryID || subType?.SubCategoryID == 0)) && {label: (subcategories.find((m) => m.ID == subType?.SubCategoryID) && subcategories.find((m) => m.ID == subType?.SubCategoryID).Name), value: subType?.SubCategoryID}) || ''}
                                                onChange={(event) => setSubType({SubCategoryID: event.value})}
                                                options={subcategories.map(c => ({
                                                    label: c.Name,
                                                    value: c.ID
                                                }))}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group col-xl-1 col-sm-1">
                                    </div>
                                    <div className="form-group col-xl-2 col-sm-2">
                                        <div className="input-group">
                                            <div className="kt-input-icon kt-input-icon--left">
                                                <input 
                                                    type="text" 
                                                    className="form-control clientSearch" 
                                                    placeholder="Search Item..." 
                                                    id="generalSearch" 
                                                />
                                                <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="tblRateMaster" className="kt-datatable table-striped clickable"></div>
                            {rightSideDetails && <RightSideDetails title="Rate Master Details" onCloseDrawer={closeDrawer}>
                                <div className="row">
                                    <div className={`col-12`}> 
                                        <div className="kt-portlet">
                                            <div className="kt-portlet__body">
                                                <div className="kt-widget kt-widget--user-profile-3">
                                                    <div className="kt-widget__top">
                                                        {itemDetails.Image && itemDetails.Image != '' ? 
                                                            <img className="kt-widget__pic kt-widget__pic" src={itemDetails.Image} alt="user-profile"/> :
                                                            <img className="kt-widget__pic kt-widget__pic" src={defaultItemImg} alt="user-profile" />
                                                        }   
                                                        <div className="kt-widget__content">
                                                            <div className="kt-widget__head">
                                                                <span className="kt-widget__username">
                                                                    {itemDetails.Name}{' '}
                                                                    &nbsp;&nbsp;&nbsp;
                                                                    <span className={`kt-badge kt-badge--${itemDetails.Active == 'Y' ? 'success' : 'grey'} kt-badge--inline kt-badge--pill padding-lr10`}>
                                                                        {itemDetails.Active == 'Y' ? 'Active' : 'Inactive'}
                                                                    </span> 
                                                                </span>
                                                            </div>
                                                            {itemDetails?.Code && 
                                                                <div className="kt-widget__subhead padding-t0">
                                                                    <span className="padding-r10">
                                                                    <i className="fa fa-dot-circle"></i>  
                                                                    {itemDetails.Code} 
                                                                    </span>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-portlet kt-portlet--mobile">
                                    <div className="kt-portlet__body padding-t0">
                                        <RateModal
                                            menuItem = {itemDetails}
                                        />
                                    </div>
                                </div>
                            </RightSideDetails>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RateMaster;