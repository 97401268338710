
import { useEffect, useState } from "react";
import RightSideDetails from "../global/rightSideDetails";
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import { deleteSubscriptionChargesByID, getSubscriptionChargesBySubscriptionID, getSubscriptionMasterAll } from "../api/paymentAPI";
import subscriptionImg from '../../assets/images/subscription_img.png'
import subscriptionImgClub from '../../assets/images/subscription_img_club.png'
import AddChargesModal from "./addChargesModal";

const SubscriptionCharges = (props) => {
    const [subscriptionMaster,          setSubscriptionMaster]            = useState({});
    const [subscriptionMasterList,      setSubscriptionMasterList]        = useState([]);
    const [isInit,                      setIsInit]                        = useState(true);
    const [rightSideDetails,            setRightSideDetails]              = useState(false);
    const [addSubscriptionCharges,      setAddSubscriptionCharges]        = useState(false);
    const [isInitChrg,                  setIsInitChrg]                    = useState(true);
    const [subscriptionChargesList,     setSubscriptionChargesList]       = useState([]);
    const [subscriptionChargesDetails,  setSubscriptionChargesDetails]    = useState({});
    const [refresh,                     setRefresh]                       = useState(false);

    useEffect(() => {
        props.title("Subscription Charges", "subscription-charges")
        fetchSubscriptionMaster();
    }, []);

    useEffect(() => {  
        rightSideDetails ? Config.Core.OpenDrawer() : setSubscriptionMaster({}), setIsInitChrg(true);;
    }, [rightSideDetails]);

    useEffect(() => {       
        if (!isInit) fillDataTable();
    }, [subscriptionMasterList]);

    useEffect(() => {       
        if (!isInitChrg) {
            fillSubscriptionChargesData();        
        }
    }, [subscriptionChargesList]);

    useEffect(() => {   
        if(subscriptionMaster.ID){
            fetchSubscriptionCharges(subscriptionMaster.ID);
        }    
    }, [subscriptionMaster.ID, refresh]);

    useEffect(() => {
        $('[data-toggle="kt-popover"]').popover();
        $('[data-toggle="kt-tooltip"]').tooltip();
        $('body').on('click', function (e) {
            $('[data-toggle="kt-popover"]').each(function () {
                // hide any open popovers when the anywhere else in the body is clicked
                if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
                    $(this).popover('hide');
                }
            });
        });
        helper.SetScrollHeight();
    });

    const refetchCharges = () => setRefresh((r) => !r);

    const fetchSubscriptionMaster = async() =>{
        try {
            const master = await getSubscriptionMasterAll()
            isInit ? setIsInit(false) : $('#tblSubscriptionMaster').KTDatatable().destroy(); 
            setSubscriptionMasterList(master);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    };

    const fetchSubscriptionCharges = async(subscriptionID) =>{
        try {
            const charges = await getSubscriptionChargesBySubscriptionID(subscriptionID)   
            isInitChrg ? setIsInitChrg(false) : $('#tblSubscriptionCharges').KTDatatable().destroy(); 
            setSubscriptionChargesList(charges);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }; 

    function fillDataTable() {

        const dataTable = $('#tblSubscriptionMaster').KTDatatable({
            data: {
                saveState: false,
                source: subscriptionMasterList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'Name',
                    title: 'Name',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                            <div class="kt-user-card-v2">
                                ${`<img src="${row.MembershipType == 'Golf Membership' ? subscriptionImg : subscriptionImgClub}" alt="user-profile" height="40" width="40">`}
                            </div>
                            <div class="kt-user-card-v2__details text-clip ml-3">
                                    <span class="kt-user-card-v2__name text-clip"> ${row.Name}  </span> <span class="kt-user-card-v2__desc text-clip"> ${row.Code} </span>
                                </div>
                           </div>`
                    }
                },
                {
                    field: 'MembershipType',
                    title: 'Membership',
                    template: function (row) {
                        return row.MembershipType == 'Golf Membership' ? `<span class="kt-badge kt-badge--success kt-badge--pill padding-lr20 padding-tb10"> ${row.MembershipType.slice(0, 4)} </span>` : `<span class="kt-badge kt-badge--info kt-badge--pill  padding-lr20 padding-tb10">${row.MembershipType.slice(0, 4)}</span>`

                    },
                },
                {
                    field: 'Active',
                    title: 'Active',
                    template: function (row) {
                        return row.Active == 'N' ? '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10"> No </span>' : '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                    },
                    width: 50,
                },
            ],
            rows: {
                autoHide: false,
            }
        });

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblSubscriptionMaster .kt-datatable__table').addClass('overflow-x-auto');


        dataTable.on('click', 'tr', function () {

            const index  = ($("tr").index(this) - 1);
            if (dataTable.dataSet[index]) {
                setSubscriptionMaster(dataTable.dataSet[index]);  
                setRightSideDetails(true);           
            } 
        });

    };

    function fillSubscriptionChargesData() {

        const dataTable = $('#tblSubscriptionCharges').KTDatatable({
            data: {
                saveState: false,
                source: subscriptionChargesList.SubscriptionChargesList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#chargesSearch'),
            },
            columns: [
                {
                    field: '',
                    title: 'Payment Date',
                    template: function (row) {
                        return `<strong style="font-weight: 600"> <span class="kt-user-card-v2__desc text-clip"> ${moment(moment(row.FromDate).format('YYYY-MM-DD')).isSame(moment(row.ToDate).format('YYYY-MM-DD')) ? moment(row.FromDate).format('DD MMM YYYY') : moment(row.FromDate).format('DD MMM YYYY') + ' - ' + moment(row.ToDate).format('DD MMM YYYY') } </span></strong>`
                    }
                },
                {
                    field: 'SubscriptionValidity',
                    title: 'Subscription Validity',
                    template: function (row) {
                        return `<strong style="font-weight: 600"> <span class="kt-user-card-v2__desc text-clip"> ${moment(moment(row.SubscriptionStartsFrom).format('YYYY-MM-DD')).isSame(moment(row.SubscriptionExpiresOn).format('YYYY-MM-DD')) ? moment(row.SubscriptionStartsFrom).format('DD MMM YYYY') : moment(row.SubscriptionStartsFrom).format('DD MMM YYYY') + ' - ' + moment(row.SubscriptionExpiresOn).format('DD MMM YYYY') } </span></strong>`
                    }
                },
                {
                    field: 'Charge',
                    title: 'Charge',
                    width: 100,
                    template: function (row) {
                        return `<strong style="font-weight: 600"> ₹ ${row.Charge}</strong>`
                    }
                },
                {
                    field: 'GST',
                    title: 'GST',
                    width: 100,
                    template: function (row) {
                        return `<strong style="font-weight: 600"> ${row.GST}%</strong>`
                    }
                },
                {
                    field: 'GSTAmount',
                    title: 'GST Amount',
                    width: 100,
                    template: function (row) {
                        return `<strong style="font-weight: 600"> ₹ ${row.GSTAmount}</strong>`
                    }
                },
                {
                    field: 'Total',
                    title: 'Total',
                    width: 100,
                    template: function (row) {
                        return `<strong style="font-weight: 600"> ₹ ${row.Total}</strong>`
                    }
                },
                {
                    field: 'Action',
                    title: 'Action',
                    width: 60,
                    template: function (row) {
                        return showEditButton(row)
                    }
                },

            ]
        });
        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblSubscriptionCharges .kt-datatable__table').addClass('overflow-x-auto');

        function showEditButton(row) {
            var optString = '';

            const isEdit = moment(moment(subscriptionChargesList.ServerDate).format('DD-MMM-YYYY')).isSameOrBefore(row.FromDate);

            optString += '<center>'

            optString += `<button type="button"  class="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs btn-edit"><i class="flaticon2-edit"></i></button>`
            if (isEdit) optString += `&nbsp;<button type="button"  class="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs btn-delete"><i class="flaticon2-rubbish-bin-delete-button"></i></button>`

            optString += '</center>'

            return optString;
        }

        dataTable.on('click', '.btn-edit', function (ss) {///edit
            const index = $(ss.currentTarget).parents('tr').index();
               
            if (dataTable.dataSet && dataTable.dataSet.length > 0 && dataTable.dataSet[index]) {
                setSubscriptionChargesDetails(dataTable.dataSet[index])
                setAddSubscriptionCharges(true);
            } 
        });

        dataTable.on('click', '.btn-delete', function (ss) {///delete
            const index = $(ss.currentTarget).parents('tr').index();
               
            if (dataTable.dataSet && dataTable.dataSet.length > 0 && dataTable.dataSet[index]) {
                deleteHandler(dataTable.dataSet[index].ID)
            } 
        });
    }

        
    const deleteHandler = async(id) => {   
        try {
            const swalResult = await swal.fire({
                title               : 'Are you sure?',
                text                : "You won't be able to revert this!",
                icon                : 'warning',
                showCancelButton    : true,
                buttonsStyling      : false,
                reverseButtons      : true,
                showLoaderOnConfirm : true,
                confirmButtonClass  : 'btn btn-brand',
                cancelButtonClass   : 'btn btn-secondary',
                confirmButtonText   : 'Delete',
                preConfirm: async () => {
                  try {
                     await deleteSubscriptionChargesByID(id)  
                } catch (err) {
                    swal.fire({
                      icon                : 'error',
                      titleText           : 'Error!',
                      text                : err.message,
                      buttonsStyling      : false,
                      confirmButtonClass  : 'btn btn-brand',
                    });
                    return;
                  }
                },
            });
            if (!swalResult.value) return;
            swal.fire({
                icon: 'success',
                titleText: 'Charges Deleted Successfully!',
                showConfirmButton: false,
                timer: 1500,
            });
            refetchCharges();
        } catch (err) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    };

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);        
    };

    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-b0" id="kt_content" style={{paddingTop:' 0px'}}>
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet kt-portlet--mobile">
                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                            <div className="kt-portlet__head-label">
                                <div className="row align-items-center margin-l10">
                                    <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                        <div className="kt-input-icon kt-input-icon--left">
                                            <input type="text" className="form-control clientSearch" placeholder="Search Master..." id="generalSearch" style={{width: '107%'}} />
                                            <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__body kt-portlet__body--fit">
                            <div id="tblSubscriptionMaster" className="kt-datatable table-striped clickable"></div>
                            {rightSideDetails && <RightSideDetails title="Subscription Charges Details" onCloseDrawer={closeDrawer}>
                                <div className="row">
                                    <div className={`col-12`}> 
                                        <div className="kt-portlet">
                                            <div className="kt-portlet__body">
                                                <div className="kt-widget kt-widget--user-profile-3">
                                                    <div className="kt-widget__top">
                                                        <img style={{height:'70px'}} src={subscriptionMaster.MembershipType == 'Golf Membership' ? subscriptionImg : subscriptionImgClub} alt="user-profile"/>
                                                        <div className="kt-widget__content">
                                                            <div className="kt-widget__head padding-b0">
                                                                <span className="kt-widget__username">
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        {subscriptionMaster.Name}{' '}&nbsp;&nbsp;
                                                                        <span
                                                                            className={`kt-badge kt-badge--${
                                                                                subscriptionMaster.MembershipType === 'Golf Membership' ? 'success' : 'info'
                                                                            } kt-badge--inline kt-badge--pill padding-lr10`}
                                                                            >
                                                                            {subscriptionMaster.MembershipType}
                                                                        </span>
                                                                        &nbsp;
                                                                        <span
                                                                            type="button"
                                                                            className={`kt-badge kt-badge--inline kt-badge--pill padding-lr10`}
                                                                            data-toggle="kt-popover"
                                                                            data-trigger="hover"
                                                                            data-placement="right"
                                                                            data-content={subscriptionMaster.Description}
                                                                        >
                                                                            <i className="la la-exclamation-circle" style={{ color: 'grey', fontSize: "26px", transform: 'rotate(180deg)' }}></i>
                                                                        </span>
                                                                    </div>
                                                                </span>
                                                            </div>
                                                            <div className="kt-widget__subhead padding-t0">
                                                                <span>
                                                                    {subscriptionMaster.Code} 
                                                                </span>
                                                            </div>
                                                            
                                                        </div>
                                                        <div className="kt-widget__action">
                                                            <span className="dropdown">
                                                                <span style={{height: '20px', width: '60px', fontSize: '12px'}} className={`kt-badge kt-badge--${subscriptionMaster.Active == 'Y' ? 'success' : 'grey'} kt-badge--inline kt-badge--pill padding-lr10 margin-r50`}>
                                                                    Active
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-portlet kt-portlet--mobile">
                                    <div className="kt-portlet__body padding-t0">
                                    <div className="kt-portlet">
                                        <div className="kt-portlet__body padding-0">
                                            <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                                                <div className="kt-portlet__head-label">
                                                    <div className="row align-items-center margin-l10">
                                                        <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                                            <div className="kt-input-icon kt-input-icon--left">
                                                                <input type="text" className="form-control clientSearch" placeholder="Search Charges..." id="chargesSearch" />
                                                                <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="kt-portlet__head-toolbar">
                                                    <div className="kt-portlet__head-wrapper">
                                                        <div className="kt-portlet__head-actions">
                                                            <button type="button" onClick={() => setAddSubscriptionCharges(true)}  className="btn btn-brand btn-icon-sm text-white d-inline"><i className="la la-plus"></i>New Charges</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="kt-portlet__body kt-portlet__body--fit">
                                                <div id="tblSubscriptionCharges" className="kt-datatable table-striped clickable">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </RightSideDetails>}
                        </div>
                    </div>
                </div>
            </div>
            {addSubscriptionCharges && (
                <AddChargesModal
                    show={addSubscriptionCharges}
                    onDismissModal={setAddSubscriptionCharges}
                    refetchCharges={() => refetchCharges()}
                    setSubscriptionChargesDetails={setSubscriptionChargesDetails}
                    subscriptionChargesDetails={subscriptionChargesDetails}
                    subscriptionChargesList={subscriptionChargesList}
                    SubscriptionID={subscriptionMaster.ID}
                />
            )}  
        </>
    )
}

export default SubscriptionCharges;