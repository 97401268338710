import { getAuthState } from "../../assets/scripts/login-util";
import { axiosPost, axiosGet, axiosDelete, axiosPatch } from "./axiosFunctions";

const getOnAccountPaymentByDateUrl                   = (fDate, tDate) => `/api/admin/payment/getOnAccountPaymentByDate/${fDate}/${tDate}`;
const getPrimaryMembersForMonthlyReportUrl           = (date) => `/api/admin/payment/getPrimaryMembersForMonthlyReport/${date}`;
const validateMonthlyPaymentReportUrl                = (date) => `/api/admin/payment/validateMonthlyPaymentReport/${date}`;
const validateMemberForMonthlyPaymentUrl             = `/api/admin/payment/validateMemberForMonthlyPayment`;
const addMonthlyPaymentUrl                           = `/api/admin/payment/addMonthlyPayment`;
const getMonthlyPaymentByDateUrl                     = (date) => `/api/admin/payment/getMonthlyPaymentByDate/${date}`;
const updateMonthlyPaymentUrl                        = `/api/admin/payment/updateMonthlyPayment`;
const postOfflineMonthlyPaymentUrl                   = `/api/admin/payment/postOfflineMonthlyPayment`;
const deleteMonthlyPaymentByIDUrl                    = (id) => `/api/admin/payment/deleteMonthlyPaymentByID/${id}`;
const postSubscriptionMasterUrl                      = '/api/admin/payment/postSubscriptionMaster';
const getSubscriptionMasterAllUrl                    = '/api/admin/payment/getSubscriptionMasterAll';
const postSubscriptionChargesUrl                     = '/api/admin/payment/postSubscriptionCharges';
const getSubscriptionChargesBySubscriptionIDUrl      = (subscriptionID) => `/api/admin/payment/getSubscriptionChargesBySubscriptionID/${subscriptionID}`;
const deleteSubscriptionChargesByIDUrl               = (id) => `/api/admin/payment/deleteSubscriptionChargesByID/${id}`;
const getSubscriptionPaymentByDateUrl                = (fDate, tDate) => `/api/admin/payment/getSubscriptionPaymentByDate/${fDate}/${tDate}`;
const getSubscriptionValidityForAdminUrl             = (groupID) => `/api/admin/payment/getSubscriptionValidityForAdmin/${groupID}`;
const getSubscriptionMasterByMembershipTypeUrl       = (membershipType) => `/api/admin/payment/getSubscriptionMasterByMembershipType/${membershipType}`;
const getSubscriptionChargesByDateAndSubIDUrl        = (subscriptionID) => `/api/admin/payment/getSubscriptionChargesByDateAndSubID/${subscriptionID}`;
const postOfflineSubscriptionUrl                     = '/api/admin/payment/postOfflineSubscription';


export const getOnAccountPaymentByDate = async (fDate, tDate) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
      getOnAccountPaymentByDateUrl(fDate, tDate),
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getPrimaryMembersForMonthlyReport = async (date) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
      getPrimaryMembersForMonthlyReportUrl(date),
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const validateMonthlyPaymentReport = async (excel, date) => {
  const token = getAuthState('token');
  if (!token) setTimeout(() => { return }, 2000);

  const formData = new FormData()
  formData.append('file', excel)

  const response = await axiosPost(
    validateMonthlyPaymentReportUrl(date),
    formData ,
    {
      headers: { auth_token: `bearer ${token}`, "Content-Type":"multipart/form-data" },
      onUploadProgress(progress) { console.log('file upload progress:', `${progress.loaded}/${progress.total}`)},
  }
  );
  return response;
};

export const validateMemberForMonthlyPayment = async (mobile) => {
  const token = getAuthState('token');
  if (!token) setTimeout(() => { return }, 2000);
  
  const response = await axiosPost(
      validateMemberForMonthlyPaymentUrl,
      { mobile },
      { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const addMonthlyPayment = async (userList, date) => {
  const token = getAuthState('token');
  if (!token) setTimeout(() => { return }, 2000);
  
  const response = await axiosPost(
      addMonthlyPaymentUrl,
      { userList, date },
      { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const getMonthlyPaymentByDate = async (date) => {
  const token = getAuthState('token');
  if (!token) setTimeout(() => { return }, 2000);

  const response = await axiosGet(
    getMonthlyPaymentByDateUrl(date),
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const updateMonthlyPayment = async (memberDetails) => {
  const token = getAuthState('token');
  if (!token) setTimeout(() => { return }, 2000);
  
  const response = await axiosPost(
      updateMonthlyPaymentUrl,
      { memberDetails },
      { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const postOfflineMonthlyPayment = async (memberDetails) => {
  const token = getAuthState('token');
  if (!token) setTimeout(() => { return }, 2000);
  
  const response = await axiosPost(
      postOfflineMonthlyPaymentUrl,
      { memberDetails },
      { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};
 
export const deleteMonthlyPaymentByID = async (id) => {
  const token = getAuthState('token');
  if (!token) setTimeout(()=> { return }, 2000);
  
  const response = await axiosDelete(
    deleteMonthlyPaymentByIDUrl(id),
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};
 
export const postSubscriptionMaster = async (masterDetails) => {
  const token = getAuthState('token');
  if (!token) setTimeout(() => { return }, 2000);
  
  const response = await axiosPost(
    postSubscriptionMasterUrl,
    { masterDetails },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const getSubscriptionMasterAll = async() => {
  const token = getAuthState('token');
  if (!token) setTimeout(() => { return }, 2000);
  
  const response = await axiosGet( getSubscriptionMasterAllUrl, { headers: { auth_token: `bearer ${token}` } } );
  return response;
}

export const postSubscriptionCharges = async (chargeDetails) => {
  const token = getAuthState('token');
  if (!token) setTimeout(() => { return }, 2000);
  
  const response = await axiosPost(
    postSubscriptionChargesUrl,
    { chargeDetails },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const getSubscriptionChargesBySubscriptionID = async (subscriptionID) => {
  const token = getAuthState('token');
  if (!token) setTimeout(() => { return }, 2000);

  const response = await axiosGet(
    getSubscriptionChargesBySubscriptionIDUrl(subscriptionID),
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const deleteSubscriptionChargesByID = async (id) => {
  const token = getAuthState('token');
  if (!token) setTimeout(()=> { return }, 2000);
  
  const response = await axiosDelete(
    deleteSubscriptionChargesByIDUrl(id),
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const getSubscriptionPaymentByDate = async (fDate, tDate) => {
  const token = getAuthState('token');
  if (!token) setTimeout(() => { return }, 2000);

  const response = await axiosGet(
    getSubscriptionPaymentByDateUrl(fDate, tDate),
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const getSubscriptionValidityForAdmin = async (groupID) => {
  const token = getAuthState('token');
  if (!token) setTimeout(() => { return }, 2000);

  const response = await axiosGet(
    getSubscriptionValidityForAdminUrl(groupID),
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const getSubscriptionMasterByMembershipType = async (membershipType) => {
  const token = getAuthState('token');
  if (!token) setTimeout(() => { return }, 2000);

  const response = await axiosGet(
    getSubscriptionMasterByMembershipTypeUrl(membershipType),
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const getSubscriptionChargesByDateAndSubID = async (subscriptionID) => {
  const token = getAuthState('token');
  if (!token) setTimeout(() => { return }, 2000);

  const response = await axiosGet(
    getSubscriptionChargesByDateAndSubIDUrl(subscriptionID),
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};

export const postOfflineSubscription = async (subDetails) => {
  const token = getAuthState('token');
  if (!token) setTimeout(() => { return }, 2000);
  
  const response = await axiosPost(
    postOfflineSubscriptionUrl,
    { subDetails },
    { headers: { auth_token: `bearer ${token}` } }
  );
  return response;
};