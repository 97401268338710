import { useEffect, useState } from "react";
import { getActiveFacilityMasterBySportID, getActiveSportsMaster, getSportsBookSheet, getSportsBookingDetails, postSportsBook, deleteSportBooking } from "../api/sportsAPI";
import RightSideDetails from "../global/rightSideDetails";
import Select from 'react-select';
import swal from 'sweetalert2';
import ic_check_on from '../../assets/images/ic_check_on.png'
import AddGuest from "../teeTime/addGuest";
import AddMarker from "./addMarker";
import * as helper from '../global/helper';
import SessionModal from "./sessionModal";
import AddPayment from "../teeTime/addPayment";
import Loading from "../global/loading";

const SportsBookingSheet = (props) => {

    const [selectedDate,                    setSelectedDate]                    = useState(moment().toDate());
    const [rightSideDetails,                setRightSideDetails]                = useState(false);
    const [sportTemplate,                   setSportTemplate]                   = useState({});
    const [isInit,                          setIsInit]                          = useState(true);
    const [sports,                          setSports]                          = useState([]);
    const [bookSheet,                       setBookSheet]                       = useState({});
    const [bookingUserList,                 setBookingUserList]                 = useState([]);
    const [type,                            setType]                            = useState({});
    const [sportsBookSheet,                 setSportsBookSheet]                 = useState([]);
    const [bookingTable,                    setBookingTable]                    = useState(null);
    const [allFacilities,                   setAllFacilities]                   = useState([]);
    const [facilityID,                      setFacilityID]                      = useState(null);
    const [typeOption,                      setTypeOption]                      = useState([]);
    const [showAddGuest,                    setShowAddGuest]                    = useState(false);
    const [showAddMarker,                   setShowAddMarker]                   = useState(false);
    const [usersList,                       setUsersList]                       = useState([]);
    const [memberList,                      setMemberList]                      = useState([]);
    const [guestList,                       setGuestList]                       = useState([]);
    const [markerList,                      setMarkerList]                      = useState([]);
    const [mobileCodeList,                  setMobileCodeList]                  = useState([]);
    const [paymentTagging,                  setPaymentTagging]                  = useState({});
    const [paymentTaggingList,              setPaymentTaggingList]              = useState([]);
    const [showAddPayment,                  setShowAddPayment]                  = useState({});
    const [loading,                         setLoading]                         = useState(false);
    const [markerDisplayName,               setMarkerDisplayName]                = useState();

    useEffect(() => {
        props.title("Sports Booking Sheet", "sports-booking")
        $('#txtDate').datepicker({
            format: 'dd-mm-yyyy',
            minDate: new Date(),
        });  
        $('#txtDate').on('change', function (event) {
            setSelectedDate(event.target.value)
        }); 
        fetchSports();
    },[])
    
    useEffect(() => {
        if(selectedDate && type?.SportID){
            setTimeout(() => {
                fetchSportsBookSheetData(moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), type.SportID)
            }, 100);
        }
    },[selectedDate, type])

    useEffect(() => {
        if(type?.SportID){
            fetchFacilityMasterBySportID(type.SportID)
        }
    },[type])

    useEffect(() => {       
        if (!isInit) fillDataTable(facilityID);
    }, [sportsBookSheet]);

    useEffect(() => {
        if(facilityID && !isInit){
            $('#tblBookSheet').KTDatatable().destroy(); 
            fillDataTable(facilityID);
        }
    },[facilityID])

    useEffect(() => {  
        if (paymentTagging && Object.keys(paymentTagging).length !== 0) {
            setPaymentTaggingList((a) => {
                const filterA = a.filter((c) => (c.UserID != paymentTagging.UserID || c.UserType != paymentTagging.UserType));
                const newA = [...filterA];
                newA.push((paymentTagging))
                return newA
            })
        }
    }, [paymentTagging]);

    useEffect(() => {  
        if(rightSideDetails){
            Config.Core.OpenDrawer()      
        }else{
            setBookSheet({})
            setBookingUserList([])
            setPaymentTagging({})
            setPaymentTaggingList([])

        }
    }, [rightSideDetails]);

    useEffect(() => {
        if(bookingUserList){
            fetchBookingDetails(bookSheet.ID || 0, type?.SportID)    
            let ids = new Set(bookingUserList.map(({ UserID }) => UserID));
            setUsersList((a) => {
                const nA = a.filter(({ ID }) => !ids.has(ID));
                return nA;
            })
            let idsGuest = new Set(bookingUserList.map(({ ID }) => ID));
            setGuestList((a) => {
                const nA = a.filter(({ ID }) => !idsGuest.has(ID));
                return nA;
            })
            let idsMarker = new Set(bookingUserList.map(({ ID }) => ID));
            setMarkerList((a) => {
                const nA = a.filter(({ ID }) => !idsMarker.has(ID));
                return nA;
            })
        }
    },[bookingUserList])

    const fetchBookingDetails = async(id, sportID) =>{
        try {
            setLoading(true);
            const booking = await getSportsBookingDetails(id, sportID)
            const filteredUsers = booking.BookingUsers ?  booking.Users.filter( g => booking.BookingUsers.findIndex( f => ((f.UserID == g.ID) )) === -1 ) : booking.Users.filter( g =>  bookingUserList.findIndex( f => (f.UserType == "User" &&  (f.UserID == g.ID) )) === -1 );
            const filteredGuests = booking.BookingUsers ?  booking.Guests.filter( g => booking.BookingUsers.findIndex( f => ((f.UserID == g.ID) )) === -1 ) : booking.Guests.filter( g => bookingUserList.findIndex( f => (f.UserType == "Guest" && (f.UserID == g.ID) )) === -1 );
            const filteredMarkers = booking.BookingUsers ?  booking.Markers.filter( g => booking.BookingUsers.findIndex( f => ((f.UserID == g.ID) )) === -1 ) : booking.Markers.filter( g => bookingUserList.findIndex( f => (f.UserType == "Marker" && (f.UserID == g.ID) )) === -1 );
            setMemberList(booking.Users);
            setUsersList(filteredUsers);
            setGuestList(filteredGuests);
            setMarkerList(filteredMarkers);
            setMobileCodeList(booking.MobileCode)
            if(booking.BookingUsers && bookingUserList.length == 0) setBookingUserList(booking.BookingUsers)
            if(booking.sportsPaymentTagging) setPaymentTaggingList(booking.sportsPaymentTagging)
            setLoading(false);

        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
            setLoading(false);

        }
    }

    const fetchSports = async() => {
        try {
            const response = await getActiveSportsMaster();
            response.length > 0 && setType({'SportID': response[0].ID})
            setSports(response)
        } catch (err) {
            swal.fire({ icon :'error', titleText : err.message })
        }
    }

    const fetchFacilityMasterBySportID = async(id) => {
        try {
            const master = await getActiveFacilityMasterBySportID(id);
            master.length > 0 && setFacilityID(master[0].ID), setAllFacilities(master);
            
        } catch (err) {
            swal.fire({ icon :'error', titleText : err.message })
        }
    }

    const fetchSportsBookSheetData = async(date, sportID) => {
        try {
            const response = await getSportsBookSheet(date, sportID);
            isInit ? setIsInit(false) : $('#tblBookSheet').KTDatatable().destroy(); 
            setSportsBookSheet(response);
         
        } catch (err) {
            swal.fire({
                icon      : 'error',
                titleText : err.message
            })  
        }
    }

    const updateBookingDetails = (field, value) => { 
        setBookSheet((d) => {
            return { ...d, [field]: value };
        });
    };

    const updateTypeDetails = (field, value) => { 
        setAllFacilities([])  
        setFacilityID(null)
        setType((d) => {
            return { ...d, [field]: value };
        });
    };

    function exportSheet(btnType) {
        let fileName = `${moment(selectedDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} - Sports Booking Sheet }`;

        bookingTable.update({
            headers: true,
            formats: ["xlsx", "csv"],
            filename: fileName // pass in a new set of properties
        });
        
        if (btnType == 'xlsx') {
            $('.button-default.xlsx').click();
        } else {
            $('.button-default.csv').click();
        }
        
        $('.tableexport-caption').addClass('hidden');
    }

    function fillDataTable(facilityID) {
        swal.close();

        var check = '<img src="' + ic_check_on + '" style="height: 20px; width: 20px; float:right;"/>';

        var bookingList = [];

        var  bookingList = sportsBookSheet.BookSheet.filter(i => i.FacilityID == facilityID).sort((a, b) => {
                var c = moment(moment($('#txtDate').val(), 'DD-MM-YYYY').format("DD-MMM-YYYY") + ' ' + a.Slot, "DD-MMM-YYYY").format("DD-MMM-YYYY hh:mm A");
                var d = moment(moment($('#txtDate').val(), 'DD-MM-YYYY').format("DD-MMM-YYYY") + ' ' + b.Slot, "DD-MMM-YYYY").format("DD-MMM-YYYY hh:mm A");

                if (moment(d).isBefore(moment(c))) {
                    return 1;
                } else if (moment(c).isBefore(moment(d))) {
                    return -1;
                } else {
                    return 0;
                }
            });

        if (sportsBookSheet.Holiday != null) {
            $('#lblHoliday').text("Holiday : " + sportsBookSheet.Holiday.Name);
        } else {
            $('#lblHoliday').text("");
        }

        $.each(bookingList, function (index, element) {

            if (element.Player1IsPresent == 'Y') {
                element["Player1"] += check;
            }
            if (element.Player2IsPresent == 'Y') {
                element["Player2"] += check;
            }
            if (element.Player3IsPresent == 'Y') {
                element["Player3"] += check;
            }
            if (element.Player4IsPresent == 'Y') {
                element["Player4"] += check;
            }

        });

        const dataTable = $('#tblBookSheet').KTDatatable({
            data: {
                saveState: false,
                source: bookingList,
            },
            layout: {
                scroll: false,
                footer: false,
            },
            sortable: false,
            pagination: false,
            search: {
                input: $('#generalSearch'),
            },
            columns: bookingList.length > 0 && bookingList[0].BookingType == 'Slot' ? 
            [
                {
                    field: 'BookingType',
                    title: `Slot`,
                    width: 130,
                    template:  function (row) {
                        return row.Slot;
                    }
                },
                {
                    field: 'Status',
                    title: 'Status',
                    width: 85,
                    template: function (row) {
                        if (row.Status == null) {
                            return "<div class='badge badge-success'><span>Available</span></div>";
                        } else {
                            return '<div class="badge badge-danger"><span>' + row.Status + '</span></div>';
                        }
                    }
                },
                {
                    field: 'Player1',
                    title: 'Player 1',
                    template: function (row) {
                        if (row?.Player1) {
                            return `<span class='kt-badge kt-badge--${row.UserType1 == "User" ? 'success' : (row.UserType1 == "Guest" || row.UserType1 == "UsersGuest") ? 'warning' : 'danger'} kt-badge--dot'></span>&nbsp;&nbsp;${row.Player1}`
                        }else {
                            return '';
                        }
                    }
                },
                {
                    field: 'Player2',
                    title: 'Player 2',
                    template: function (row) {
                        if (row?.Player2) {
                            return `<span class='kt-badge kt-badge--${row.UserType2 == "User" ? 'success' : (row.UserType2 == "Guest" || row.UserType2 == "UsersGuest") ? 'warning' : 'danger'} kt-badge--dot'></span>&nbsp;&nbsp;${row.Player2}`
                        }else {
                            return '';
                        }
                    }
                },
                {
                    field: 'Player3',
                    title: 'Player 3',
                    template: function (row) {
                        if (row?.Player3) {
                            return `<span class='kt-badge kt-badge--${row.UserType3 == "User" ? 'success' : (row.UserType3 == "Guest" || row.UserType3 == "UsersGuest") ? 'warning' : 'danger'} kt-badge--dot'></span>&nbsp;&nbsp;${row.Player3}`
                        }else {
                            return '';
                        }
                    }
                },
                {
                    field: 'Player4',
                    title: 'Player 4',
                    template: function (row) {
                        if (row?.Player4) {
                            return `<span class='kt-badge kt-badge--${row.UserType4 == "User" ? 'success' : (row.UserType4 == "Guest" || row.UserType4 == "UsersGuest") ? 'warning' : 'danger'} kt-badge--dot'></span>&nbsp;&nbsp;${row.Player4}`
                        }else {
                            return '';
                        }
                    }
                },
                {
                    field: 'CreatedDate',
                    title: 'Time',
                    width: 145,
                    template: function (row) {
                        if (row.CreatedDate == null) {
                            return "";
                        } else {
                            return moment(row.CreatedDate).format('DD-MMM hh:mm:ss.SS A');
                        }
                    }
                },
                {
                    field: '',
                    title: '',
                    width: 60,
                    template: function (row) {

                        if (row.Status == "Booked" || row.Status == null) {
                            return showEditButton(row)
                        } else {
                            return '';
                        }
                    }
                },

            ] : 
            [
                {
                    field: 'BookingType',
                    title: `Session`,
                    width: 130,
                    class: "clickable",
                    template:  function (row) {
                        return row.Slot;
                    }
                },
                {
                    field: 'BookedSession',
                    title: 'Bookings',
                    width: 85,
                    class: "clickable",
                    template: function (row) {
                        return '<div class="badge grey-background"><span>' + row.BookedSession + '</span></div>';
                    }
                },
                {
                    field: 'AvailableSession',
                    title: 'Available',
                    width: 85,
                    class: "clickable",
                    template: function (row) {
                        return '<div class="badge badge-success"><span>' + row.AvailableSession + '</span></div>';
                    }
                },
                {
                    field: 'Total',
                    title: 'Total',
                    width: 85,
                    class: "clickable",
                    template: function (row) {
                        return '<div class="badge badge-info"><span>' + row.Total + '</span></div>';

                    }
                },
                {
                    field: '',
                    title: '',
                    width: 60,
                    class: "clickable",
                    template: function (row) {

                        if (!row.BlockedStatus && row.BookedSession != row.Total) {
                            return showEditButton(row)
                        } else {
                            return row?.BlockedStatus && '<div class="badge badge-danger"><span>' + row.BlockedStatus + '</span></div>';
                        }
                    }
                },

            ],
            rows: {
                autoHide: false,
            }
        });

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblBookSheet .kt-datatable__table').addClass('overflow-x-auto');

        Config.Core.InitPopover();
        Config.Core.GrabAndScroll('.kt-datatable__table');

        // export to excel
        const BookingTable = $('.kt-datatable__table').tableExport({
            formats: ["xlsx", "csv"],
            headers: true,
            filename: `${moment().format("DD-MM-YYYY")} - Sports Booking Sheet`,
        });
        setBookingTable(BookingTable);
        $('.tableexport-caption').addClass('hidden');
        
        function showEditButton(row) {

            const formattedDate = moment($('#txtDate').val(), 'DD/MM/YYYY').format('DD-MMM-YYYY');

            var optString = '';

            const isEdit = moment(moment(sportsBookSheet.ServerDate).format('DD-MMM-YYYY')).isSameOrBefore(formattedDate);

            optString += '<center>'

            if (row.Comment && row.BookingType != 'Session') optString += `<button type="button" class="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs mr-1" data-toggle="kt-popover"  data-trigger="hover click" data-placement="left" data-original-title="Comments" data-content="${row.Comment}"><i class="fa fa-info"></i></button>`

            if (isEdit) optString += row.BookingType != 'Session' ? `<button type="button"  class="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs btn-edit"><i class="flaticon2-edit"></i></button>` : `<button type="button"  class="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs btn-edit" data-toggle="kt-popover"  data-trigger="hover click" data-content="New Booking"><i class="flaticon2-add-1"></i></button>`

            optString += '</center>'

            return optString;
        }

        dataTable.on('click', '.btn-edit', function (ss) {
            const index = $(ss.currentTarget).parents('tr').index();
               
            if (dataTable.dataSet && dataTable.dataSet.length > 0 && dataTable.dataSet[index]) {
                setMarkerDisplayName(dataTable.dataSet[index].MarkerDisplayName);
                setTypeOption([{isY: dataTable.dataSet[index].MaximumMemberAdmin == 0 ? 'N' : 'Y', value:  'Member', label: 'Member'}, {isY: dataTable.dataSet[index].MaximumGuestAdmin == 0 ? 'N' : 'Y', value:  'Guest', label: 'Guest'}, {isY: dataTable.dataSet[index].MaximumMarkerAdmin == 0 ? 'N' : 'Y', value: dataTable.dataSet[index].MarkerDisplayName, label: dataTable.dataSet[index].MarkerDisplayName}])
                setBookSheet({...dataTable.dataSet[index], Type: 'Member', TemplateID: dataTable.dataSet[index].TemplateID});  
                dataTable.dataSet[index].BookingType != "Session" && fetchBookingDetails(dataTable.dataSet[index].ID || 0, dataTable.dataSet[index].SportID)
                setRightSideDetails(true); 
            } 
        });

        dataTable.on('click', 'tr', function (s) {
            if ($(s.target).index() == 4 || $(s.target).closest('button').index() == 0 ) { // prevent row click 
                return;
            }
            const index = ($("tr").index(this) - 1);
            if (dataTable.dataSet[index] && dataTable.dataSet[index].BookingType == 'Session') {
                setSportTemplate(dataTable.dataSet[index]); 
            }    
        });
    }
        
    const addBookingUserHandler = () =>{
        if(bookSheet.UserID){
            setBookingUserList((a) => {
                const newA = [...a];
                const newO = usersList.find((f) => f.ID == bookSheet.UserID)
                newA.push({...newO,  UserID: newO.ID, UserType: "User"})
                return newA;
            })
            setBookSheet(a => ({...a, UserID: '', GuestID: '', MarkerID: ''}))

        }
    }

    const addBookingGuestHandler = () =>{
        if(bookSheet.GuestID){
            setBookingUserList((a) => {
                const newA = [...a];
                const newO = guestList.find((f) => f.ID == bookSheet.GuestID)
                newA.push({...newO,  UserID: newO.ID, UserType: "Guest"})
                return newA
            })
            setBookSheet(a => ({...a, UserID: '', GuestID: '', MarkerID: ''}))
            paymentHandler({...guestList.find((f) => f.ID == bookSheet.GuestID), UserID: bookSheet.GuestID, UserType: "Guest"});

        }
    }

    const addBookingMarkerHandler = () =>{
        if(bookSheet.MarkerID){
            setBookingUserList((a) => {
                const newA = [...a];
                const newO = markerList.find((f) => f.ID == bookSheet.MarkerID)
                newA.push({...newO,  UserID: newO.ID, UserType: "Marker"})
                return newA
            })
            setBookSheet(a => ({...a, UserID: '', GuestID: '', MarkerID: ''}))
            paymentHandler({...guestList.find((f) => f.ID == bookSheet.MarkerID), UserID: bookSheet.MarkerID, UserType: "Marker"});

        }
    }

    const addBookingHandler = async() => {
        const btn = $('#btnSave');
        helper.StartProcessing(btn);
       
        if(bookingUserList.length < bookSheet.MinimumPlayerAdmin || bookingUserList.length > bookSheet.MaximumPlayerAdmin){
            swal.fire({
                icon                  : 'warning',
                titleText             : `${bookingUserList.length < bookSheet.MinimumPlayerAdmin ? `Minimum ${bookSheet.MinimumPlayerAdmin} players required` : `Maximum ${bookSheet.MaximumPlayerAdmin} players are allowed`}`,
                buttonsStyling        : false,  
                confirmButtonClass    : 'btn btn-brand',   
            });
            helper.StopProcessing(btn);
            return 
        }////Minimum Maximum Player////

        if(bookSheet.MinimumMemberAdmin != 0 && (bookingUserList.filter(f => f.UserType == "User").length > bookSheet.MaximumMemberAdmin || bookingUserList.filter(f => f.UserType == "User").length  < bookSheet.MinimumMemberAdmin)){
            swal.fire({
                icon                  : 'warning',
                titleText             : bookingUserList.filter(f => f.UserType == "User").length > bookSheet.MaximumMemberAdmin ? `Maximum ${bookSheet.MaximumMemberAdmin} members are allowed` : `Minimum  ${bookSheet.MinimumMemberAdmin}  members required`,
                buttonsStyling        : false,  
                confirmButtonClass    : 'btn btn-brand',   
            });
            helper.StopProcessing(btn);
            return 
        };////Member////

        if(bookSheet.MinimumGuestAdmin != 0 && (bookingUserList.filter(f => f.UserType == "Guest").length > bookSheet.MaximumGuestAdmin || bookingUserList.filter(f => f.UserType == "Guest").length  < bookSheet.MinimumGuestAdmin)){
            swal.fire({
                icon                  : 'warning',
                titleText             : bookingUserList.filter(f => f.UserType == "Guest").length > bookSheet.MaximumGuestAdmin ? `Maximum ${bookSheet.MaximumGuestAdmin} guest are allowed` : `Minimum  ${bookSheet.MinimumGuestAdmin}  guest required`,
                buttonsStyling        : false,  
                confirmButtonClass    : 'btn btn-brand',   
            });
            helper.StopProcessing(btn);
            return 
        };////Guest////

        if(bookSheet.MinimumMarkerAdmin != 0 && (bookingUserList.filter(f => f.UserType == "Marker").length > bookSheet.MaximumMarkerAdmin || bookingUserList.filter(f => f.UserType == "Marker").length  < bookSheet.MinimumMarkerAdmin)){
            swal.fire({
                icon                  : 'warning',
                titleText             : bookingUserList.filter(f => f.UserType == "Marker").length > bookSheet.MaximumMarkerAdmin ? `Maximum ${bookSheet.MaximumMarkerAdmin} ${markerDisplayName} are allowed` : `Minimum  ${bookSheet.MinimumMarkerAdmin}  ${markerDisplayName} required`,
                buttonsStyling        : false,  
                confirmButtonClass    : 'btn btn-brand',   
            });
            helper.StopProcessing(btn);
            return 
        };////Marker////

        try {
            const bookSheetDetails = {
                ID:                 bookSheet.ID || 0,
                SportID:            bookSheet.SportID,
                SportName:          bookSheet.SportName,
                BookingType:        bookSheet.BookingType,
                Slot:               bookSheet.Slot,
                FacilityID:         bookSheet.FacilityID,
                TemplateID:         bookSheet.TemplateID,
                Index:              bookSheet.Index,
                ConcurrentBooking:  bookSheet.ConcurrentBooking,
                BookDate:           moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                Comment:            bookSheet.Comment,
            }

            let bookingUser = [];

            bookingUserList.forEach(function (e, i) {
                bookingUser.push({
                    ID:                 0, 
                    SportBookID:        bookSheet.SportBookID || 0,
                    UserID:             e.UserID,  
                    UserType:           e.UserType
                });
            });

            const sportsPaymentTagging = paymentTaggingList.map((t) =>({
                ...t,
                ID:                     t.ID || 0,
                UserID:                 t.UserID,
                SportBookUserID:        0,
                ReferenceID:            t.ReferenceID || 0,
                ReferenceType:          t.ReferenceType || 'Direct',
                PaymentMode:            t.PaymentMode || 'Direct',
                ReferenceName:          t.ReferenceName || ''

            }))
          
            await postSportsBook(bookSheetDetails, bookingUser, sportsPaymentTagging)
            helper.StopProcessing(btn);
            setTimeout(() => {
                fetchSportsBookSheetData(moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), type.SportID)
            }, 500);
            closeDrawer();
            if (bookSheetDetails.ID) {
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'Booking updated successfully!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,
                    animation: false,
                    customClass: {
                        popup: 'margin-20',
                    },
                });
            }else{
                swal.fire({
                    icon: 'success',
                    titleText: 'Booking added successfully!',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    const paymentHandler = (value) => {
        if(value.UserType == 'Guest' || value.UserType == 'Marker'){
            if(paymentTaggingList && paymentTaggingList.length > 0){
                setPaymentTagging({...paymentTaggingList.find((f) => f.UserID == value.UserID && f.UserType == value.UserType && f.SportBookUserID == value.SportBookUserID)})
            }else{
                setPaymentTagging({})
            }
            setShowAddPayment(value)
        }
    }

    const cancelBookingHandler = async() => {
        const btn = $('#btnCancel');
        helper.StartProcessing(btn);
        try {
            const isEmptyOrSpaces = bookSheet.Comment === null || bookSheet.Comment.match(/^ *$/) !== null || /\s/.test(bookSheet.Comment);

            await deleteSportBooking(bookSheet.ID, isEmptyOrSpaces ? null : bookSheet.Comment)
            helper.StopProcessing(btn);
            setTimeout(() => {
                fetchSportsBookSheetData(moment(selectedDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), type.SportID)
            }, 1000);
            closeDrawer();
            swal.fire({
                icon: 'success',
                titleText: 'Booking deleted successfully!',
                showConfirmButton: false,
                timer: 1500,
            });
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    const resetUser = () => {
        setBookSheet(a => ({...a, UserID: ''}))
    }

    const resetGuest = () => {
        setBookSheet(a => ({...a, GuestID: ''}))
    }

    const resetMarker = () => {
        setBookSheet(a => ({...a, MarkerID: ''}))
    }

    const closeDrawer = () =>{
        bookSheet?.SportBookID && bookSheet.BookingType == "Session" && setSportTemplate(bookSheet)
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);
    }

    const deleteUserHandler = (index, value) => {
        setBookingUserList(p => {
            const nA = p.filter((f, i) => index !== i )
            return nA
        })
        if(value.UserType == 'Guest' || value.UserType == 'Marker'){
            setPaymentTaggingList(p => {
                const nA = p.filter((f) => value.UserID !== f.UserID )
                return nA
            })    
        }
    }

    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content"  >
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet">
                        <div className="kt-portlet__body padding-t30 padding-b0">
                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                <div className="row">
                                    <label className="col-form-label col-sm-1 text-right">Date </label>
                                    <div className="form-group col-xl-2 col-sm-2">
                                        <div className="input-group date">
                                            <input 
                                                id="txtDate" 
                                                name="txtDate" 
                                                type="text" 
                                                className="form-control " 
                                                placeholder="Select date" 
                                                defaultValue={moment(selectedDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} 
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <i className="la la-calendar-check-o"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <label className="col-form-label col-sm-1 text-right  ">Sport</label>
                                    <div className="form-group col-sm-2 ">
                                        <div>
                                            <Select 
                                                id="ddlSport" 
                                                name="ddlSport"
                                                className="multi-custom"
                                                value={(sports && sports.length > 0 && type.SportID) && {label: sports.find((m) => m.ID == type.SportID).Name ,value: type.SportID} || ''}
                                                onChange={(event) => updateTypeDetails('SportID', event.value)}
                                                options={sports.map(c => ({
                                                    label: c.Name,
                                                    value: c.ID
                                                }))}
                                            />
                                        </div>
                                    </div>
                                    <label className="col-form-label col-sm-1 text-right">Facility</label>
                                    <div className="form-group col-sm-2">
                                        <div>
                                            <Select 
                                                id="ddlFacility" 
                                                name="ddlFacility"
                                                className="multi-custom width-95p"
                                                value={((allFacilities && allFacilities.length > 0 && !!facilityID) && {label: (allFacilities.find((m) => m.ID == facilityID) && allFacilities.find((m) => m.ID == facilityID).Name), value: facilityID}) || ''}
                                                onChange={(event) => setFacilityID(event.value)}
                                                options={allFacilities.map(c => ({
                                                    label: c.Name,
                                                    value: c.ID
                                                }))}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group col-xl-2 col-sm-2">
                                        <div className="input-group">
                                            <div className="kt-input-icon kt-input-icon--left">
                                                <input 
                                                    type="text" 
                                                    className="form-control clientSearch" 
                                                    placeholder="Search Booking..." 
                                                    id="generalSearch" 
                                                />
                                                <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-1 col-sm-1">
                                        <div className="dropdown">
                                            <button className="btn btn-label-brand dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Export
                                            </button>
                                            <div className="dropdown-menu " aria-labelledby="dropdownMenuButton" x-placement="bottom-start" style={{position: 'absolute', willChange: 'transform', top: '0px', left: '0px', transform: 'translate3d(0px, 38px, 0px)'}}>
                                                <button 
                                                    id="btn-export-excel" 
                                                    type="button" 
                                                    className="dropdown-item" 
                                                    onClick={() => exportSheet('xlsx')}
                                                >Excel
                                                </button>
                                                <button 
                                                    id="btn-export-csv" 
                                                    type="button" 
                                                    className="dropdown-item" 
                                                    onClick={() => exportSheet('csv')}
                                                >Csv 
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h5 id="lblHoliday" className="lblHoliday"></h5>
                            <div id="tblBookSheet" className="table table-bordered table-hover custom-datatable"></div>
                            {rightSideDetails && (<RightSideDetails title="Book Sport" onCloseDrawer={closeDrawer}>
                                <div className="kt-portlet">
                                    <div className="kt-portlet__body padding-t30 padding-b0">
                                        <form id="ModalHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Date</label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <input 
                                                        id="lblDate" 
                                                        name="lblDate" 
                                                        disabled="disabled" 
                                                        type="text" 
                                                        className="form-control" 
                                                        value={moment(selectedDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} 
                                                    />
                                                </div>
                                            </div>
                                            <div id="pnlEdit" className="form-group row">
                                                <label className="col-form-label col-lg-3">{bookSheet.BookingType} <span className="red">*</span></label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <div>
                                                        <Select 
                                                            id="ddlTemplateID" 
                                                            name="ddlTemplateID"
                                                            className="multi-custom"
                                                            value={(sportsBookSheet && sportsBookSheet.BookSheet.length > 0 && bookSheet.TemplateID) && {label: sportsBookSheet.BookSheet.find(c => c.TemplateID == bookSheet.TemplateID).Slot, value: bookSheet.TemplateID} || ''}
                                                            onChange={(event) => updateBookingDetails('TemplateID', event.value)}
                                                            options={sportsBookSheet.BookSheet.filter(f => f.BookingType == bookSheet.BookingType && f.FacilityID == facilityID).map(a => ({
                                                                label: a.Slot,
                                                                value: a.TemplateID,
                                                            }))}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="pnlType" className="form-group row">
                                                <label className="col-form-label col-lg-3">Type <span className="red"></span></label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <div>
                                                        <Select 
                                                            id="ddlType" 
                                                            name="ddlType"
                                                            className="multi-custom"
                                                            placeholder="Select Type"
                                                            defaultValue={ typeOption.find((m) => m.value[0] )  || ''}
                                                            onChange={(event) => updateBookingDetails('Type', event.value)}
                                                            options={typeOption.filter(f => f.isY == "Y")}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {bookSheet.Type == "Member" ? (
                                                <div id="UserHolder">
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">User</label>
                                                        <div className="col-xl-6 col-lg-8">
                                                            <div className="input-group" >
                                                                <div style={{width: "60%"}}  >
                                                                    <Select
                                                                        value={(usersList && usersList.length > 0 && bookSheet.UserID) && {label: `${usersList.find(m => m.ID == bookSheet.UserID).Name} (${usersList.find(m => m.ID == bookSheet.UserID).Mobile})`, value: bookSheet.UserID} || ''}
                                                                        onChange={(event) => updateBookingDetails('UserID', event.value)}
                                                                        name="ddlUser"
                                                                        placeholder="Select User"
                                                                        id="ddlUser"
                                                                        className="ddlUser"
                                                                        options={usersList.map(c => ({
                                                                            label: `${c.Name} (${c.Mobile})`,
                                                                            value: c.ID
                                                                        }))}
                                                                    />
                                                                </div>
                                                                <div className="input-group-append" style={{zIndex: 0}}>
                                                                    <button className="btn btn-secondary kt-select-reset" type="button" onClick={resetUser}><i className="flaticon2-delete kt-icon-sm padding-0"></i></button>
                                                                </div>
                                                                <button id="btnAddUser" className="btn btn-label-brand margin-l10 margin-r0" type="button"  onClick={addBookingUserHandler}>Add</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : bookSheet.Type == "Guest" ? (
                                                <div id="GuestHolder" >
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">Guest</label>
                                                        <div className="col-xl-6 col-lg-8">
                                                            <div className="input-group">
                                                                <div style={{width: "60%"}}  >
                                                                    <Select
                                                                        value={(guestList && guestList.length > 0 && bookSheet.GuestID) && {label: `${guestList.find(m => m.ID == bookSheet.GuestID).Name} (${guestList.find(m => m.ID == bookSheet.GuestID).Mobile})`, value: bookSheet.GuestID}}
                                                                        onChange={(event) => updateBookingDetails('GuestID', event.value)}
                                                                        name="ddlGuest"
                                                                        placeholder="Select Guest"
                                                                        id="ddlGuest"
                                                                        data-live-search="true"
                                                                        className="ddlGuest"
                                                                        options={guestList.map(c => ({
                                                                            label: `${c.Name} (${c.Mobile})`,
                                                                            value: c.ID
                                                                        }))}
                                                                    />
                                                                </div>
                                                                <div className="input-group-append"  style={{zIndex: 0}}>
                                                                    <button className="btn btn-secondary kt-select-reset" type="button" onClick={resetGuest}><i className="flaticon2-delete kt-icon-sm padding-0"></i></button>
                                                                </div>
                                                                <button id="btnAddGuest" className="btn btn-label-brand margin-l10 margin-r0" type="button" onClick={addBookingGuestHandler}>Add</button>
                                                                <button id="btnNewGuest" className="btn btn-label-brand margin-l10" type="button" data-toggle="modal" data-target="#modalGuest"  onClick={() => setShowAddGuest(true)} >New</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ): (
                                                <div id="MarkerHolder" >
                                                    <div className="form-group row">
                                                        <label className="col-form-label col-lg-3">{markerDisplayName}</label>
                                                        <div className="col-xl-6 col-lg-8">
                                                            <div className="input-group">
                                                                <div style={{width: "60%"}}  >
                                                                    <Select
                                                                        value={(markerList && markerList.length > 0 && bookSheet.MarkerID) && {label: `${markerList.find(m => m.ID == bookSheet.MarkerID).Name} ${!!markerList.find(m => m.ID == bookSheet.MarkerID).Mobile ? '(' + markerList.find(m => m.ID == bookSheet.MarkerID).Mobile + ')' : ''}`, value: bookSheet.MarkerID}}
                                                                        onChange={(event) => updateBookingDetails('MarkerID', event.value)}
                                                                        name="ddlMarker"
                                                                        placeholder={`Select ${markerDisplayName}`}
                                                                        id="ddlMarker"
                                                                        data-live-search="true"
                                                                        className="ddlMarker"
                                                                        options={markerList.map(c => ({
                                                                            label: `${c.Name} ${c.Mobile ? '(' + c.Mobile + ')' : ''}`,
                                                                            value: c.ID
                                                                        }))}
                                                                    />
                                                                </div>
                                                                <div className="input-group-append"  style={{zIndex: 0}}>
                                                                    <button className="btn btn-secondary kt-select-reset" type="button" onClick={resetMarker}><i className="flaticon2-delete kt-icon-sm padding-0"></i></button>
                                                                </div>
                                                                <button id="btnAddMarker" className="btn btn-label-brand margin-l10 margin-r0" type="button" onClick={addBookingMarkerHandler}>Add</button>
                                                                <button id="btnNewMarker" className="btn btn-label-brand margin-l10" type="button" data-toggle="modal" data-target="#modalMarker"  onClick={() => setShowAddMarker(true)} >New</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Comment</label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <textarea 
                                                        id="txtComment" 
                                                        name="txtComment" 
                                                        className="form-control" 
                                                        rows="3"
                                                        value={bookSheet.Comment || ''}
                                                        onChange={(event) => updateBookingDetails('Comment', event.target.value)} 
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </form>
                                        {loading ? <Loading/> : (<table id="table-user" className="table table-bordered table-hover margin-b30">
                                            <thead className="light-grey-background">
                                                <tr>
                                                    <th>Players</th>
                                                    <th className="text-center">Action</th>
                                                </tr>
                                            </thead>
                                            {bookingUserList && bookingUserList.length > 0 &&
                                                <tbody>
                                                    {bookingUserList.map((m, i) => (
                                                        <tr key={i}>
                                                            <td onClick={() => {!m.SportBookID  && paymentHandler(m)}}><span className={`kt-badge kt-badge--${m.UserType == "User" ? 'success' : (m.UserType == "Guest" || m.UserType == "UsersGuest") ? 'warning' : 'danger'} kt-badge--dot`}></span>&nbsp; &nbsp;{m.UserNameAdmin || m.Name}{(m.UserType == "Guest" || m.UserType == "Marker") && !m.SportBookID && ` ${m.UserType == "Guest" ? '(G)' : ''} - ${(paymentTaggingList.length > 0 && paymentTaggingList.find(f => f.UserID == m.UserID && f.UserType == m.UserType && (f.SportBookUserID == m.SportBookUserID || f.SportBookUserID == m.ID)) ? paymentTaggingList.find(f => f.UserID == m.UserID && f.UserType == m.UserType).TagTo : "Direct")}`}</td>
                                                            <td>
                                                                <center>
                                                                    <button className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10 btn-user-delete" type="button" onClick={() => deleteUserHandler(i, m)} >                  
                                                                        <i className="flaticon2-rubbish-bin-delete-button"></i>             
                                                                    </button>
                                                                </center>
                                                            </td>
                                                        </tr>
                                                    )
                                                    )}
                                                </tbody>
                                            }
                                            <tfoot></tfoot>
                                        </table>)}
                                        <div className="row margin-bl5">
                                            <div className="col-sm-12 pl-0">
                                                <h6 className="margin-0">
                                                    <strong id="lblOwner">
                                                        {bookingUserList && bookingUserList.length > 0 && bookingUserList[0].UserName && 'Booked By ' + (bookSheet.OwnerType == 'Admin' ? 'Admin' : bookingUserList[0].UserName) }
                                                    </strong>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="kt-portlet__foot">
                                        <div className="kt-form__actions">
                                            <div className="row">
                                                <div className="col-lg-12 text-right">
                                                    <button id="btnSave" type="button" className="btn btn-brand d-inline-block" onClick={addBookingHandler}>{bookSheet.ID ? 'Update Booking' : 'Save Booking' }</button>
                                                    {bookSheet && bookSheet.ID && <button id="btnCancel" type="button" className="btn btn-warning d-inline-block margin-l10" onClick={cancelBookingHandler}>Cancel Booking</button>}
                                                    <button id="btnCloseDrawer" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}>Close</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </RightSideDetails>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {Object.keys(sportTemplate).length > 0 && (
                <SessionModal
                    show={sportTemplate}
                    sportTemplate={sportTemplate} 
                    selectedDate={selectedDate}
                    setRightSideDetails={setRightSideDetails}
                    setTypeOption={setTypeOption}
                    setBookSheet={setBookSheet}
                    fetchBookingDetails={fetchBookingDetails}
                    onDismissModal={setSportTemplate} 
                />
            )}
            {showAddGuest && (
                <AddGuest
                    show={showAddGuest}
                    onDismissModal={setShowAddGuest} 
                    setShowAddPayment={setShowAddPayment} 
                    mobileCodeList={mobileCodeList}
                    fetchBookingDetails={() => fetchBookingDetails(bookSheet.ID || 0, type.SportID)} 
                    bookingUserList={bookingUserList}
                />
            )}
            {showAddMarker && (
                <AddMarker
                    show={showAddMarker}
                    onDismissModal={setShowAddMarker} 
                    setShowAddPayment={setShowAddPayment} 
                    markerDisplayName={markerDisplayName} 
                    SportID={bookSheet.SportID} 
                    mobileCodeList={mobileCodeList}
                    fetchBookingDetails={() => fetchBookingDetails(bookSheet.ID || 0, type.SportID)} 
                    bookingUserList={bookingUserList}
                />
            )}
            {(showAddPayment && Object.keys(showAddPayment).length !== 0) && (
                <AddPayment
                    show={showAddPayment}
                    MemberDetails={showAddPayment}
                    onDismissModal={setShowAddPayment} 
                    setPaymentTagging={setPaymentTagging} 
                    paymentTagging={paymentTagging} 
                    MemberList={memberList} 
                    GuestList={guestList} 

                />
            )}
        </>
    )
}

export default SportsBookingSheet;

