import { getAuthState } from "../../assets/scripts/login-util";
import { axiosPost, axiosGet, axiosDelete, axiosPatch } from "./axiosFunctions";

const postOnboardingDataUrl                               = '/api/admin/onboarding/postOnboardingData';
const getOnboardingUserAllUrl                             = '/api/admin/onboarding/getOnboardingUserAll';

export const getOnboardingUserAll = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosGet(
        getOnboardingUserAllUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const postOnboardingData = async (memberDetails) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
    
    const response = await axiosPost(
        postOnboardingDataUrl,
        { memberDetails},
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};
