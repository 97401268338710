import swal from 'sweetalert2';
import Select from 'react-select';
import { useEffect, useState } from "react";
import * as helper from '../global/helper';
import { postGuest, sendGuestOTP } from '../api/usersAPI';

const AddGuest = (props) => {

    const [guest,                   setGuest]                     = useState({});
    const [otpModal,                setOtpModal]                  = useState(false);
    const [sessionId,               setSessionId]                 = useState();
    const [otp,                     setOtp]                       = useState();

    useEffect(() => {
        $('#modalGuest').modal({
            backdrop: 'static',
        });
        $('#modalGuest').on('hidden.bs.modal', function () {
            $('.modal').hasClass('show') && $('body').addClass('modal-open');
            props.onDismissModal(false);
        });
      
    }, [])

    useEffect(() => {
        if(props.mobileCodeList){
            setGuest({CountryCode : props.mobileCodeList[0].Code})
        }
    },[props.mobileCodeList])

    const updateGuestDetails = (field, value) => { 
        setGuest((d) => {
            return { ...d, [field]: value };
        });
    };

    const addGuestHandler = async(e) => {
        e.preventDefault()
        const btn = $('#btnSaveGuest');

       try {
        const guestDetails = {
            ID:                    guest.ID || 0,
            Name:                  guest.Name,
            Email:                 guest.Email || '',
            Handicap:              guest.Handicap,
            IGUNumber:             guest.IGUNumber || '',
            CountryCode:           guest.CountryCode,
            Mobile:                guest.Mobile,
            Active:                'Y'
        }
        const newG = await postGuest(guestDetails, otp, sessionId);
        helper.StopProcessing(btn);
        $('#modalGuest').modal('hide');
        props.bookingUserList.push({...newG,  UserID: newG.ID, UserType: "Guest"})
        props.setShowAddPayment(newG.ID)
        props.fetchBookingDetails()
        swal.fire({
            icon: 'success',
            titleText: 'Guest added successfully!',
            showConfirmButton: false,
            timer: 1500,
        });
        
       } catch (err) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
       }
    }

    const otpHandler = async() => {
        
        var form = $('#add-guest-form');
        
        form.validate({
            rules: {
                txtGuestName: {
                    required: true,
                },
                txtGuestMobile: {
                    required: true,
                    number: true,
                },
                txtGuestHandicap: {
                    required: true
                }                         
            },
            messages: {
                txtGuestName: {
                    required: "Please enter name"
                },
                txtGuestMobile: {
                    required: "Please enter mobile",
                },
                txtGuestHandicap: {
                    required: "Please enter handicap"
                }
            }
        });

        
        if (!form.valid()) return;
        
        swal.fire({
            titleText           : 'Loading',
            text                : 'Please Wait...',
            showConfirmButton   : false,
            allowOutsideClick   : false,
            onOpen: () => {
                swal.showLoading();
            }
        });

        
        try {
            
            const session = await sendGuestOTP(guest.Mobile, 0)
            setSessionId(session)
            setOtpModal(true)
            swal.close();
            
        } catch (err) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return; 
        }
    }

    return(
        <>
            <div id="modalGuest" className="modal fade modal-responsive" tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Guest</h5>
                            <button 
                                type="button" 
                                className="close" 
                                data-dismiss="modal" 
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <form className="kt-form" id="add-guest-form">
                                <div id="ModalGuestHolder" className="kt-form kt-form--label-right" role="form">
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-3">Name <span className="red">*</span></label>
                                        <div className="col-xl-6 col-lg-8">
                                            <input 
                                                id="txtGuestName" 
                                                name="txtGuestName" 
                                                type="text" 
                                                className="form-control" 
                                                value={guest.Name || ''}
                                                onChange={(event) => updateGuestDetails('Name', helper.camelCase(event.target.value))} 
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-3">Mobile <span className="red">*</span></label>
                                        <div className="col-xl-2 col-lg-3">
                                            <Select
                                                value={(props.mobileCodeList && props.mobileCodeList.length > 0 && guest.CountryCode) && {label: `${props.mobileCodeList.find(m => m.Code == guest.CountryCode).Name} ${props.mobileCodeList.find(m => m.Code == guest.CountryCode).Code}`, value: guest.CountryCode} || ''}
                                                onChange={(event) => updateGuestDetails('CountryCode', event.value)}
                                                name="ddlCountryCode"
                                                id="ddlCountryCode"
                                                className="ddlCountryCode"
                                                options={props.mobileCodeList.map(c => ({
                                                    label: `${c.Name} (${c.Code})`,
                                                    value: c.Code
                                                }))}
                                            />
                                        </div>
                                        <div className="col-xl-4 col-lg-5">
                                            <input 
                                                id="txtGuestMobile" 
                                                name="txtGuestMobile" 
                                                type="number" 
                                                className="form-control"
                                                value={guest.Mobile || ''}
                                                onChange={(event) => updateGuestDetails('Mobile', event.target.value)} 
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-3">Handicap <span className="red">*</span></label>
                                        <div className="col-xl-6 col-lg-8">
                                            <input 
                                                id="txtGuestHandicap" 
                                                name="txtGuestHandicap" 
                                                type="number" 
                                                className="form-control"
                                                value={guest.Handicap || ''}
                                                onChange={(event) => updateGuestDetails('Handicap', event.target.value)} 
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-3">Email </label>
                                        <div className="col-xl-6 col-lg-8">
                                            <input 
                                                id="txtGuestEmail" 
                                                name="txtGuestEmail" 
                                                type="text" 
                                                className="form-control" 
                                                value={guest.Email || ''}
                                                onChange={(event) => updateGuestDetails('Email', event.target.value)} 
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-3">IGU Number</label>
                                        <div className="col-xl-6 col-lg-8">
                                            <input 
                                                id="txtIGUNumber" 
                                                name="txtIGUNumber" 
                                                type="text" 
                                                className="form-control" 
                                                value={guest.IGU || ''}
                                                onChange={(event) => updateGuestDetails('IGU', event.target.value)} 
                                            />
                                        </div>
                                    </div>
                                    {otpModal && (
                                        <div id="OTPHolder">
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Enter Otp <span className="red">*</span></label>
                                                <div className="col-xl-3 col-lg-6">
                                                    <input 
                                                        id="txtOTP" 
                                                        name="txtOTP" 
                                                        type="text" 
                                                        className="form-control" 
                                                        onChange={({ target }) => setOtp(target.value)}
                                                        value={ otp || '' }
                                                    />
                                                </div>

                                            </div>
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3 "></label>

                                                <div className="col-xl-3 col-lg-3">
                                                    <button 
                                                        type="button" 
                                                        id="btnResendOtp" 
                                                        name="btnResendOtp" 
                                                        className="btn btn-warning margin-0"
                                                        onClick={otpHandler}
                                                    >
                                                        Resend Otp
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            {otpModal ? (
                                <button type="button" id="btnSaveGuest" name="btnSaveGuest" className="btn btn-brand margin-0 " onClick={addGuestHandler}>Add</button>
                            ):(
                                <button type="button" id="btnGetOtp" name="btnGetOtp" className="btn btn-brand margin-0  margin-r5" onClick={otpHandler}>Get Otp</button>

                            )}
                            <button type="button" className="btn btn-secondary margin-0 margin-r5" data-dismiss="modal">Close</button>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddGuest;