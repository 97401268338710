import { useState } from "react";
import { useEffect } from "react";
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import RightSideDetails from "../global/rightSideDetails";
import Select from 'react-select';
import '../../assets/scripts/jquery-validation.init';
import { addUser, getViewUserAll, verifyMembershipNo, verifyMobileNo } from "../api/usersAPI";
import UserDetails from "./userDetails";
import Member from "./member";
import AddMember from "./addMember";
import EditUser from "./editUser";
import ImageCropper from "../global/imageCropper";
import { uploadPrivateFile, uploadPublicFile } from "../api/fileAPI";
import AddDocument from "./addDocument";
import { useLayoutEffect } from "react";
import '../../assets/styles/wizard-3.css';
import defaultUserImg from '../../assets/images/user.jpg'
import MemberDocuments from "./memberDocuments";
import Dropzone from "../global/dropzone";
import { getSubscriptionMasterAll, getSubscriptionValidityForAdmin } from "../api/paymentAPI";
import subscription_expired from '../../assets/images/subscription_expired.png'
import UpdateSubscriptionModal from "../payment/updateSubscriptionModal";
import UserSubscription from "./userSubscription";


export const OverflowMenu = (props) => {
    return (
      <div className="dropdown-menu dropdown-menu-right" x-placement="top-end">
          <a className="dropdown-item" onClick={props.onEditClick}>
            <i className="la la-edit"></i>
            Edit
          </a>
      </div>
    );
};


const Users = (props) =>{


    const stepList = ['AddMember', 'MembershipDetails', 'Subscription', 'Documents'];

    const [usersList,                   setUsersList]                        = useState([]);
    const [userDetails,                 setUserDetails]                      = useState({Active: 'Y', Country: 'India'});
    const [members,                     setMembers]                          = useState([]);
    const [memberDetails,               setMemberDetails]                    = useState({});
    const [documentDetails,             setDocumentDetails]                  = useState({ShowOnApp: 'Y'});
    const [memberDocument,              setMemberDocument]                   = useState(null);
    const [memberDocumentList,          setMemberDocumentList]               = useState([]);
    const [editingXid,                  setEditingXid]                       = useState(null);
    const [editingDocumentXid,          setEditingDocumentXid]               = useState(null);
    const [rightSideDetails,            setRightSideDetails]                 = useState(false);
    const [navState,                    setNavState]                         = useState('All Members');
    const [userNavState,                setUserNavState]                     = useState('Members');
    const [isInit,                      setIsInit]                           = useState(true);
    const [userType,                    setUserType]                         = useState('Individual');
    const [action,                      setAction]                           = useState('a');
    const [showUserDetails,             setShowUserDetails]                  = useState();
    const [addMember,                   setAddMember]                        = useState(false);
    const [addDocument,                 setAddDocument]                      = useState(false);
    const [editDocument,                setEditDocument]                     = useState(null);
    const [refresh,                     setRefresh]                          = useState(false);
    const [documentRefresh,             setDocumentRefresh]                  = useState(false);
    const [editUser,                    setEditUser]                         = useState(false);
    const [cropperData,                 setCropperData]                      = useState(null);
    const [userPhotoBlob,               setUserPhotoBlob]                    = useState(null);
    const [step,                        setStep]                             = useState();
    const [membershipTypeOption,        setMembershipTypeOption]             = useState([{ value:  'Golf Membership', label: 'Golf Membership'}, { value:  'Club Membership', label: 'Club Membership'}]);
    const [subscriptionTypeOption,      setSubscriptionTypeOption]           = useState([]);
    const [genderOption,                setGenderOption]                     = useState([{ value:  'Male', label: 'Male'}, { value:  'Female', label: 'Female'},]);
    const [memberTypeOption,            setMemberTypeOption]                 = useState([{ value:  'Primary', label: 'Primary'}, { value:  'Spouse', label: 'Spouse'}, { value:  'Child', label: 'Child'},]);
    const [documentTypeOption,          setdocumentTypeOption]               = useState([{ value:  'AndharCard', label: 'AndharCard'},  { value:  'Pancard', label: 'Pancard'}, { value:  'Agreement', label: 'Agreement'},]);
    const [subscriptionValidity,        setSubscriptionValidity]             = useState([]);
    const [subscriptionDetails,         setSubscriptionDetails]              = useState({});
    const [showSubModal,                setShowSubModal]                     = useState(false);
    const [subRefresh,                  setSubRefresh]                       = useState(false);


    useEffect(() => {
        props.title("Users", "user")
        fetchSubscriptionMaster();
    }, []);
    
    useEffect(() => {  
        if(rightSideDetails){
            Config.Core.OpenDrawer()
            setStep('AddMember')        
            $('#txtDOB').datepicker({
                format: 'dd-mm-yyyy',
                minDate: new Date(),
            });
            $('#txtDOB').on('change', function (event) {
                updateMemberDetails('DOB',  moment(event.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
            });     
            $('#txtSubscriptionExpiresOn').datepicker({
                format: 'dd-mm-yyyy',
                minDate: new Date(),
            });
            $('#txtSubscriptionExpiresOn').on('change', function (event) {
                updateUsersDetails('SubscriptionExpiresOn',  moment(event.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
            });   
            $('#txtMembershipExpiresOn').datepicker({
                format: 'dd-mm-yyyy',
                minDate: new Date(),
            });
            $('#txtMembershipExpiresOn').on('change', function (event) {
                updateUsersDetails('MembershipExpiresOn',  moment(event.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
            }); 
        }else{
            setUserDetails({Active: 'Y', Country: 'India'});
            setMemberDetails({});
            setMembers([]);
            setAction('a');
            setUserType('Individual')
            setUserNavState('Members')
            setUserPhotoBlob(null);    
            setDocumentDetails({ShowOnApp: 'Y'}); 
            setStep()
            setMemberDocumentList([])   
            setSubscriptionValidity([])   
            setSubscriptionDetails({})   
        }
    }, [rightSideDetails]);
    
    useEffect(() => {       
        if (!isInit) {
            fillDataTable();                  
        }
    }, [usersList]);

    useEffect(() => {     
       if (members.length > 0) {
        generateMembershipNo();
       }
    }, [members]);

    useEffect(() => {       
        if (navState || refresh) {
            fetchUsers();                
        }
    }, [navState, refresh]);

    useEffect(() => {
        if (subscriptionDetails && Object.keys(subscriptionDetails).length !== 0) {
            setSubscriptionValidity((a) => {
                const updatedIndex = a.findIndex(obj => obj.OrderID === subscriptionDetails.OrderID);
                return updatedIndex !== -1
                    ? [...a.slice(0, updatedIndex), subscriptionDetails, ...a.slice(updatedIndex + 1)]
                    : [...a, subscriptionDetails];
            });
        }
    }, [subscriptionDetails]);
    
    useEffect(() => {       
        if (userNavState === "Subscription") {
            fetchSubscriptionValidity();
        }
    }, [userNavState, subRefresh]);

    useLayoutEffect(() => {
        if(!!step){
            const prevElement = document.querySelectorAll('[data-ktwizard-state = current]');
            
            prevElement.forEach( e => {
                e.removeAttribute('data-ktwizard-state');
            });

            $(`.${step}`).attr('data-ktwizard-state','current');
        }
    }, [step]);
    
    const fetchSubscriptionValidity = async() =>{
        try { 
            const res = await getSubscriptionValidityForAdmin(userDetails.GroupID);
            setSubscriptionValidity(res.filter(f => f.Status != 'Expired'));

        } catch (err) {
            swal.fire({
                icon              : 'error',
                titleText         : 'Error!',
                text              : err.message,
                buttonsStyling    : false,
                confirmButtonClass: 'btn btn-brand',
            });
            return;
        }
    };

    const fetchSubscriptionMaster = async() =>{
        try {
            const response = await getSubscriptionMasterAll()
            const master = response.filter(f => f.Active == "Y")
            setSubscriptionTypeOption(master);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    };
    
    const fetchUsers = async() => {
        try {
            const users = await getViewUserAll();
            isInit ? setIsInit(false) : $('#tblUser').KTDatatable().destroy(); 
            navState == 'All Members' ? setUsersList(users) : setUsersList([...users.filter(f => f.Category == navState)]);
            userDetails && userDetails.ID && setUserDetails((d) => users.find(f => f.ID == d.ID));
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    function fillDataTable() {

        const dataTable = $('#tblUser').KTDatatable({
            data: {
                saveState: false,
                source: usersList.filter(f => f.MemberType == 'Primary'),
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'FirstName',
                    title: 'Member',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                    <div class="kt-user-card-v2__pic">
                                    ${(row.Photo && row.Photo != '') ? `<img src="${row.Photo}" alt="user-profile" height="50" width="50">` : ` <div class="kt-badge kt-badge--xl kt-badge--${row.Category === 'Organization' ? 'success' : 'info'} ">  ${row.FirstName.substring(0, 1)}</div>`}
                                    </div>
                                    <div class="kt-user-card-v2__details text-clip">
                                        <span class="kt-user-card-v2__name text-clip"> ${row.FirstName} ${row.LastName} </span> <span class="kt-user-card-v2__desc text-clip"> ${row.MembershipNo} </span>
                                    </div>
                                </div>`
                    }
                }, 
                {
                    field: 'Email',
                    title: 'Email',
                },
                {
                    field: 'Mobile',
                    title: 'Mobile',
                },
                {
                    field: 'MembershipType',
                    title: 'Membership',
                    template: function (row) {
                        return row.MembershipType == 'Golf Membership' ? `<span class="kt-badge kt-badge--success kt-badge--pill padding-lr20 padding-tb10"> ${row.MembershipType.slice(0, 4)} </span>` : `<span class="kt-badge kt-badge--info kt-badge--pill  padding-lr20 padding-tb10">${row.MembershipType.slice(0, 4)}</span>`

                    },
                },
                {
                    field: 'Code',
                    title: 'Subscription',
                    template: function (row) {
                        return row?.Code == 'Expired' ? '<span style="width: 60px;" class="kt-badge kt-badge--grey kt-badge--pill padding-lr30 padding-tb10"> Expired </span>' : `<span  style="width: 60px;" class="kt-badge kt-badge--unified-${row?.MembershipType == 'Golf Membership' ? 'success' : 'primary'} kt-badge--pill  kt-badge--bold padding-lr20 padding-tb10">${row?.Code}</span>`

                    },
                    
                },
                {
                    field: 'IsAppUser',
                    title: 'App User',
                    template: function (row) {
                        return row.IsAppUser == 'N' ? '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10"> No </span>' : '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                    },
                  
                },
                {
                    field: 'Active',
                    title: 'Active',
                    template: function (row) {
                        return row.Active == 'N' ? '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10"> No </span>' : '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                    },
                    width: 50,
                },
            ]
        });

        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);
            if (dataTable.dataSet[index]) {
                setAction('e')
                setUserDetails(dataTable.dataSet[index]);  
                setRightSideDetails(true);           
            } 
        });

    }

    const addMembersHandler = async(e) => {
        e.preventDefault()

        var form = $('#add-member-form');
        
        form.validate({
            rules: {
                ddlMembershipType: {
                    required: true,
                },
                txtCompanyName: {
                    required: true,
                },
                txtFirstName: {
                    required: true
                },
                txtLastName: {
                    required: true
                },         
                txtMobile: {
                    required: true,
                    number: true,
                    minlength: 10,
                    maxlength: 10,
                },
                txtEmail: {
                    required: true,
                    email: true
                },
                ddlGender: {
                    required: true
                },
                txtDOB: {
                    required: true
                },
                ddlMemberType: {
                    required: true
                }                            
            },
            messages: {
                ddlMembershipType: {
                    required: "Please select membership type"
                },
                txtCompanyName: {
                    required: "Please enter company name"
                },
                txtFirstName: {
                    required: "Please enter firstname"
                },
                txtLastName: {
                    required: "Please enter lastname"
                },
                txtMobile: {
                    required: "Please enter mobile",
                },
                txtEmail: {
                    required: "Please enter email",
                    email: "Please enter a valid email"
                },
                ddlGender: {
                    required: "Please select gender"
                },
                txtDOB: {
                    required: "Please select date of birth"
                },
                ddlMemberType: {
                    required: "Please select member type"
                }
            }
        });
        if (!form.valid()) return;
        if(userType !== 'Organization'){
            if ((memberDetails.MemberType == "Primary" || memberDetails.MemberType == "Spouse") && members.length > 0 && members.find((f, i) => i !== editingXid && f.MemberType == memberDetails.MemberType)) {
                swal.fire({
                    icon                  : 'warning',
                    titleText             : 'Error!',
                    text                  : `Only 1 ${memberDetails.MemberType} member can be added`,
                    buttonsStyling        : false,
                    confirmButtonClass    : 'btn btn-brand',
                });
                return
            }
        }

        if((members.length == 0 || editingXid == 0) && memberDetails.MemberType !== "Primary"){
            swal.fire({
                icon                  : 'warning',
                titleText             : 'Error!',
                text                  : 'Kindly add primary member',
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',   
            });
            return
        }

        try {
            
            await verifyMobileNo(memberDetails.Mobile)

        } catch (err) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return
        }
        setMembers((a) => {
            const newA = [...a];
            if (editingXid || editingXid == 0) {
                newA[editingXid] = {
                ...memberDetails,
                Photo: userPhotoBlob
                };
                setEditingXid(null);
            } else {
                newA.push({
                ...memberDetails,
                Photo: userPhotoBlob,
                Category: userType
                });
            }
            return newA;
        });

        setMemberDetails({});
        setUserPhotoBlob(null)

        form.clearForm();
        form.validate().resetForm();

    }

    const addUserDetailsHandler = async(e) => {
        e.preventDefault()

        const btn = $('#btnSave');
        helper.StartProcessing(btn);

      try {

        //users
        let userList = [];

        for (const m of members) {
            let userPhoto = '';
            //Call upload API -->
            if(m.Photo && m.Photo != ''){
                const res = await uploadPublicFile(m.Photo, 'User');
                userPhoto = res.fileName;            
            }

            userList.push({
                FirstName:    m.FirstName,
                MiddleName:   m.MiddleName || '',
                LastName:     m.LastName,
                Mobile:       m.Mobile,
                Email:        m.Email,
                Gender:       m.Gender,
                Photo:        userPhoto,
                DOB:          m.DOB || '',
                RFID:         m.RFID || '',
                BagTag:       m.BagTag || '',
                MemberType:   m.MemberType,
                BloodGroup:   m.BloodGroup || '',
                Active:       userDetails.Active
            })

        }

        //members
        const memberGroup = {
            MembershipType:         userDetails.MembershipType,
            Category:               userType,
            MembershipNo:           userDetails.MembershipNo,
            CompanyName:            userDetails.CompanyName || '',
            SubscriptionExpiresOn:  userDetails.SubscriptionExpiresOn,
            MembershipExpiresOn:    userDetails.MembershipExpiresOn,
            SubscriptionID:         userDetails.SubscriptionID,
            PanCardNo:              userDetails.PanCardNo || '',
            AadharCardNo:           userDetails.AadharCardNo || '',
            GstNo:                  userDetails.GstNo || '',
            Address:                userDetails.Address || '',
            City:                   userDetails.City || '',
            State:                  userDetails.State || '',
            Country:                userDetails.Country || '',
            Pincode:                userDetails.Pincode || '',
            Phone:                  userDetails.Phone || '', 
            Nominee:                userDetails.Nominee || '',
            Remark:                 userDetails.Remark || ''   
        }

        //documents
        let documentList = [];
        for (const m of memberDocumentList) {
            let userDoc = '';
            //Call upload API -->
            if(m.FileName && m.FileName != ''){
                const res = await uploadPrivateFile(m.FileName, 'Document');
                userDoc = res.fileName;            
            }

            documentList.push({
                DocumentType:    m.DocumentType,
                FileName:        userDoc,
                Remark:          m.Remark || '',
                ShowOnApp:       m.ShowOnApp
            })

        }
        await addUser(userList, memberGroup, subscriptionValidity, documentList);
        helper.StopProcessing(btn);

        closeDrawer();
        fetchUsers();

        swal.fire({
            icon: 'success',
            titleText: 'User added successfully!',
            showConfirmButton: false,
            timer: 1500,
        });
      } catch (err) {
         helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
      }

    }

    const addDocumntHandler = (e) =>{
        e.preventDefault()

        var form = $('#add-document-form');
        
        form.validate({
            rules: {
                ddlDocumentType: {
                    required: true,
                }                
            },
            messages: {
                ddlDocumentType: {
                    required: "Please select Document Type"
                }
            }
        });
        if(!memberDocument){
            swal.fire({
                icon                  : 'warning',
                titleText             : 'Error!',
                text                  : `Please Select Document`,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',   
            });
            return
        }
        
        if (!form.valid()) return;
        setMemberDocumentList((a) =>{
            const newA = [...a]
            if(editingDocumentXid || editingDocumentXid == 0){
                newA[editingDocumentXid] = {
                    ...documentDetails,
                    FileName: memberDocument
                    };
                    setEditingDocumentXid(null)
            }else {
                newA.push({
                ...documentDetails,
                FileName: memberDocument
                });
            }
            return newA;
        })

        setDocumentDetails({ShowOnApp: 'Y'});
        setMemberDocument(null)

        form.clearForm();
        form.validate().resetForm();

    }

    const documentHandler = async (d) => {
        setMemberDocument(d);
    }; 
    
    const updateMemberHandler = (index, value) => {
        setEditingXid(index); 
        setMemberDetails({...value, Photo: ''});
        setUserPhotoBlob(value.Photo)
    };
    
    const updateDocumentHandler = (value, index) => {
        setEditingDocumentXid(index); 
        setDocumentDetails(value);
        setMemberDocument(value.FileName)
    };

    const removeMemberHandler = (index) => {
        if (index === editingXid) {
            setEditingXid(null);
            setMemberDetails({});
            setUserPhotoBlob(null);
        }
        setMembers((a) => {
            const nA = a.filter((b, i) => index !== i);
            return nA;
        });
    };

    const removeDocumentHandler = (index) => {
        if (index === editingDocumentXid) {
            setEditingDocumentXid(null);
            setDocumentDetails({ShowOnApp: 'Y'});
            setMemberDocument(null);
        }
        setMemberDocumentList((a) => {
            const nA = a.filter((b, i) => index !== i);
            return nA;
        });
    };
    
    const updateMemberDetails = (field, value) => { 
        setMemberDetails((d) => {
            return { ...d, [field]: value };
        });
    };

    const updateUsersDetails = (field, value) => { 
        setUserDetails((d) => {
            return { ...d, [field]: value };
        });
    };

    const updateDocumentDetails = (field, value) => { 
        setDocumentDetails((d) => {
            return { ...d, [field]: value };
        });
    };

    const userTypeHandler = (type) => {
        setUserType(type);
    };

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);
    }

    const generateMembershipNo = async() =>{
       if (!userDetails.MembershipNo) {
        try {
            let randomMembershipNo =  members.find(f => f.Category == 'Organization') ? 'CM' : members.find(f => f.MemberType == 'Primary').LastName.slice(0 ,1)
            const characters        = '0123456789';
            const charactersLength  = characters.length;
            for (let i = 0; i < 4; i++)
            randomMembershipNo += characters.charAt(Math.floor(Math.random() * charactersLength));

            await verifyMembershipNo(randomMembershipNo)
            
            setUserDetails((d) => {
                return { ...d, 'MembershipNo': randomMembershipNo };
            });
            
        } catch (error) {
            generateMembershipNo();
        }
       }
    }


    const updateUserHandler = () => {
        setAddMember(true)
    };

    const refetchUsers = () => setRefresh((r) => !r);
    const refetchDocuments = () => setDocumentRefresh((r) => !r);
    const refetchValidity = () => setSubRefresh((r) => !r);


    const openCropper = () =>{
        setCropperData({
            imageRatio  : { width : 200, height : 200},
            dataHandler : (val) =>  setUserPhotoBlob(val),
            header : 'Upload Profile Photo'
        })
    }
    
    const removePhotoHandler = async(e) => {
		e.preventDefault();
		setUserPhotoBlob(null)
	}
 
    const onStepChange = (currentStep) => {
        onNextHandler(currentStep);
    };

    const onNextHandler = async (steps) => {

        if(members.length <= 0){
            swal.fire({
                icon                  : 'warning',
                titleText             : 'Please add atleast 1 member',
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',   
              });
            return
        }

        
        if(step == 'MembershipDetails' && steps !== 'AddMember'){
            var form = $('#add-userDetails-form');
            
            form.validate({
                rules: {
                    txtSubscriptionExpiresOn: {
                        required: true,
                    },
                    txtMembershipExpiresOn: {
                        required: true
                    },
                    ddlSubscriptionType: {
                        required: true
                    }                         
                },
                messages: {
                    txtSubscriptionExpiresOn: {
                        required: "Please select subscription expires On"
                    },
                    txtMembershipExpiresOn: {
                        required: "Please select membership expires On"
                    },
                    ddlSubscriptionType: {
                        required: "Please select subscription Type"
                    }
                }
            });
    
            if (!form.valid()) return;
        }
        
        let currentStep = !steps ? step : steps
        
        if (steps) {
            const cstep = stepList.find(s => s == currentStep)
            if (cstep) setStep(cstep)
        } else {
            const cIndex  = stepList.findIndex( s => s == currentStep);
            if( (cIndex >= 0) & (cIndex != stepList.length) ) setStep( stepList[ cIndex + 1] );
        }
    } 

    const onPrevHandler = () => {
        const cIndex  = stepList.findIndex( s => s == step);
        if( (cIndex != 0) & (cIndex <= stepList.length) ) setStep( stepList[ cIndex - 1] );
    };

    function calculateProgress(startDate, endDate) {
        const currentDate = new Date();
        const start = new Date(startDate);
        const end = new Date(endDate);
        
        // Calculate the difference between start and end dates in milliseconds
        const totalDuration = end - start;
        
        // Calculate the progress duration in milliseconds
        const progressDuration = currentDate - start;
        
        // Calculate the progress percentage
        const progressPercentage = (progressDuration / totalDuration) * 100;
        
        // Ensure progress percentage is within 0-100 range
        return `${Math.min(100, Math.max(0, progressPercentage))}%`;
    }

     return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content" >
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet kt-portlet--mobile">
                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                            <div className="kt-portlet__head-label">
                                <nav className="file-nav navbar navbar-top">
                                    <a className={navState === "All Members" ? "top-nav-active" : ""} onClick={() => setNavState("All Members")}>  
                                        All Members 
                                    </a>
                                    <a className={navState === "Individual" ? "top-nav-active" : "" } onClick={() => setNavState("Individual")}  >  
                                         Individual
                                    </a>
                                    <a className={navState === "Organization" ? "top-nav-active" : "" } onClick={() => setNavState("Organization")}  >  
                                        Organization
                                    </a>
                                </nav>
                            </div>
                            <div className="kt-portlet__head-toolbar">
                                <div className="row align-items-center margin-r10">
                                    <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                        <div className="kt-input-icon kt-input-icon--left">
                                            <input type="text" className="form-control clientSearch" placeholder="Search Member..." id="generalSearch" />
                                            <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-portlet__head-wrapper">
                                    <div className="kt-portlet__head-actions">
                                        <button  onClick={() => setRightSideDetails(true)} type="button" className="btn btn-brand btn-icon-sm text-white d-inline"><i className="la la-plus"></i>New Member</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__body kt-portlet__body--fit">
                            <div id="tblUser" className="kt-datatable table-striped clickable"></div>
                            {rightSideDetails && <RightSideDetails title="Member Details" onCloseDrawer={closeDrawer}>
                                {action == 'a' ? 
                                    <div className="kt-portlet">
                                        <div className="kt-portlet__body padding-t10 padding-b0">
                                            <div className="kt-grid kt-wizard-v3 kt-wizard-v3--white" id="kt_wizard_v3" >
                                                <div className="kt-grid__item">
                                                {/* begin: Form Wizard Nav */}
                                                    <div className="kt-wizard-v3__nav">
                                                        <div className="row kt-wizard-v3__nav-items kt-wizard-v3__nav-items--clickable padding-0">
                                                            <div className="col-3">
                                                                <div className="kt-wizard-v3__nav-item AddMember" data-ktwizard-type="step" id="AddMember" >
                                                                    <div className="kt-wizard-v3__nav-body">
                                                                        <div className="kt-wizard-v3__nav-label mb-0" onClick={(e) => onStepChange('AddMember')} >
                                                                            <span> 1 </span> Add Members 
                                                                        </div>
                                                                        <div className="kt-wizard-v3__nav-bar"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-3">
                                                                <div className="kt-wizard-v3__nav-item MembershipDetails" data-ktwizard-type="step"  id="MembershipDetails" >
                                                                    <div className="kt-wizard-v3__nav-body">
                                                                        <div className="kt-wizard-v3__nav-label mb-0" onClick={(e) => onStepChange('MembershipDetails')} >
                                                                            <span> 2 </span> Membership Details 
                                                                        </div>
                                                                        <div className="kt-wizard-v3__nav-bar"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-3">
                                                                <div className="kt-wizard-v3__nav-item Subscription" data-ktwizard-type="step"  id="Subscription" >
                                                                    <div className="kt-wizard-v3__nav-body">
                                                                        <div className="kt-wizard-v3__nav-label mb-0" onClick={(e) => onStepChange('Subscription')} >
                                                                            <span> 3 </span> Subscription 
                                                                        </div>
                                                                        <div className="kt-wizard-v3__nav-bar"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-3">
                                                                <div className="kt-wizard-v3__nav-item Documents" data-ktwizard-type="step"  id="Documents" >
                                                                    <div className="kt-wizard-v3__nav-body">
                                                                        <div className="kt-wizard-v3__nav-label mb-0" onClick={(e) => onStepChange('Documents')} >
                                                                            <span> 4 </span> Documents 
                                                                        </div>
                                                                        <div className="kt-wizard-v3__nav-bar"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                {/* end: Form Wizard Nav */}
                                                </div>
                                                <div className="kt-grid__item kt-grid__item--fluid  ">
                                                    <div className="kt-form ">
                                                        <div id="wizard-1" className="kt-wizard-v3__content AddMember padding-0 margin-0" data-ktwizard-type="step-content" >
                                                            <div className="padding-lr10">
                                                                <form className="kt-form" id="add-member-form">
                                                                    <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                                                        <div className="form-group row margin-b10 margin-t0">
                                                                            <div className="kt-radio-inline radio-container" >
                                                                                <div className="input-group"  style={{width: '11%'}}>
                                                                                    {' '}
                                                                                    <input
                                                                                        value="Individual"
                                                                                        defaultChecked={true}
                                                                                        onChange={({ target }) => userTypeHandler(target.value)}
                                                                                        type="radio"
                                                                                        name="client-type"
                                                                                        id="indv"
                                                                                    />
                                                                                    <label htmlFor="indv">Individual</label>
                                                                                </div>
                                                                                <div className="input-group "   style={{width: '11%'}} >
                                                                                    {' '}
                                                                                    <input
                                                                                        value="Organization"
                                                                                        defaultChecked={userType == 'Organization' }
                                                                                        onChange={({ target }) => userTypeHandler(target.value)}
                                                                                        type="radio"
                                                                                        name="client-type"
                                                                                        id="corp"
                                                                                    />
                                                                                    <label htmlFor="corp">Organization</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-6 form-group ">
                                                                                <Select 
                                                                                    id="ddlMembershipType" 
                                                                                    name="ddlMembershipType"
                                                                                    className="multi-custom"
                                                                                    placeholder="Select Membership Type"
                                                                                    value={userDetails && membershipTypeOption.find((m) => m.value == userDetails.MembershipType) || ''}
                                                                                    onChange={(event) => updateUsersDetails('MembershipType', event.value)}
                                                                                    options={membershipTypeOption}
                                                                                    isDisabled={members.length == 0  || editingXid == 0  ?  false : true}

                                                                                />
                                                                            </div>
                                                                            <div className="col-6 form-group" >
                                                                                <div className="input-group ">
                                                                                    <input 
                                                                                        style={{ fontWeight: userDetails.MembershipNo ? 'bold' : '' }}
                                                                                        id="txtMembershipNo" 
                                                                                        name="txtMembershipNo " 
                                                                                        type="text" 
                                                                                        className="form-control"
                                                                                        value={userDetails.MembershipNo  || ''}
                                                                                        onChange={(event) => updateUsersDetails('MembershipNo', event.target.value)} 
                                                                                        disabled={members.length > 0 ? false : true}

                                                                                    />
                                                                                    <div className="input-group-append">
                                                                                        <span className="input-group-text">
                                                                                            Membership Number
                                                                                        </span>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                        {userType == 'Organization' && (
                                                                            <div className="row">
                                                                                <div className="col-12 form-group ">
                                                                                    <input 
                                                                                        id="txtCompanyName" 
                                                                                        name="txtCompanyName" 
                                                                                        type="text" 
                                                                                        className="form-control" 
                                                                                        placeholder="Company Name"
                                                                                        value={userDetails.CompanyName || ''}
                                                                                        onChange={(event) => updateUsersDetails('CompanyName', helper.camelCase(event.target.value))} 
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                        )}
                                                                        <div className="row">
                                                                            <div className="col-6 form-group  padding-t10">
                                                                                <div className="kt-avatar kt-avatar--outline kt-avatar--changed  offset-4" id="kt_user_add_avatar">
                                                                                    <div className="kt-avatar__holder" 
                                                                                        style={{ backgroundImage : `url( ${userPhotoBlob ? URL.createObjectURL(userPhotoBlob): memberDetails.Photo ? memberDetails.Photo : defaultUserImg})` ,width: "120px", height:"120px" } }>    
                                                                                    </div>
                                                                                    <label 
                                                                                        className="kt-avatar__upload" 
                                                                                        data-toggle="kt-tooltip"  
                                                                                        data-original-title="Change avatar"
                                                                                        onClick ={openCropper}
                                                                                    >
                                                                                        <i className="fa fa-pen"></i>
                                                                                    </label>
                                                                                    {(!!userPhotoBlob || memberDetails.Photo) && (
                                                                                        <span className="kt-avatar__cancel" data-toggle="kt-tooltip" data-original-title="Cancel avatar" onClick={removePhotoHandler} >
                                                                                            <i className="fa fa-times"></i>
                                                                                        </span>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <div className="row">
                                                                                    <div className="col-12 form-group">
                                                                                        <input 
                                                                                            id="txtFirstName" 
                                                                                            name="txtFirstName" 
                                                                                            type="text" 
                                                                                            className="form-control"
                                                                                            placeholder="First Name"
                                                                                            value={memberDetails.FirstName || ''}
                                                                                            onChange={(event) => updateMemberDetails('FirstName',  helper.camelCase(event.target.value))} 
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-12 form-group">
                                                                                        <input 
                                                                                            id="txtMiddleName" 
                                                                                            name="txtMiddleName" 
                                                                                            type="text" 
                                                                                            className="form-control"
                                                                                            placeholder="Middle Name"
                                                                                            value={memberDetails.MiddleName || ''}
                                                                                            onChange={(event) => updateMemberDetails('MiddleName',  helper.camelCase(event.target.value))} 
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-12 form-group">
                                                                                        <input 
                                                                                            id="txtLastName" 
                                                                                            name="txtLastName" 
                                                                                            type="text" 
                                                                                            className="form-control"
                                                                                            placeholder="Last Name"
                                                                                            value={memberDetails.LastName || ''}
                                                                                            onChange={(event) => updateMemberDetails('LastName',  helper.camelCase(event.target.value))}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-4 form-group ">
                                                                                <Select 
                                                                                    id="ddlMemberType" 
                                                                                    name="ddlMemberType"
                                                                                    className="multi-custom"
                                                                                    placeholder="Select Member Type"
                                                                                    value={memberDetails && memberTypeOption.find((m) => m.value == memberDetails.MemberType )  || ''}
                                                                                    onChange={(event) => updateMemberDetails('MemberType', event.value)}
                                                                                    options={memberTypeOption}
                                                                                />
                                                                            </div>
                                                                            <div className="col-4 form-group ">
                                                                                <input 
                                                                                    id="txtMobile" 
                                                                                    name="txtMobile" 
                                                                                    type="text" 
                                                                                    className="form-control"
                                                                                    minLength='10'
                                                                                    maxLength='10'
                                                                                    placeholder="Mobile"
                                                                                    value={memberDetails.Mobile || ''}
                                                                                    onChange={(event) => updateMemberDetails('Mobile', event.target.value)} 
                                                                                />
                                                                            </div>
                                                                            <div className="col-4 form-group ">
                                                                                <input 
                                                                                    id="txtEmail" 
                                                                                    name="txtEmail" 
                                                                                    type="text" 
                                                                                    className="form-control"
                                                                                    placeholder="Email "
                                                                                    value={memberDetails.Email  || ''}
                                                                                    onChange={(event) => updateMemberDetails('Email', event.target.value)} 
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-4 form-group">
                                                                                <Select 
                                                                                    id="ddlGender" 
                                                                                    name="ddlGender"
                                                                                    className="multi-custom"
                                                                                    placeholder="Select Gender"
                                                                                    value={memberDetails && genderOption.find((m) => m.value == memberDetails.Gender) || ''}
                                                                                    onChange={(event) => updateMemberDetails('Gender', event.value)}
                                                                                    options={genderOption}
                                                                                />
                                                                            </div>
                                                                            <div className="col-4 form-group ">
                                                                                <div className="input-group date">
                                                                                    <input 
                                                                                        id="txtDOB" 
                                                                                        name="txtDOB" 
                                                                                        type="text" 
                                                                                        className="form-control date-input" 
                                                                                        readOnly={true} 
                                                                                        placeholder="Select DOB" 
                                                                                        value={memberDetails.DOB ? moment(memberDetails.DOB).format('DD-MM-YYYY') : ''} 
                                                                                    />
                                                                                    <div className="input-group-append">
                                                                                        <span className="input-group-text">
                                                                                            <i className="la la-calendar-check-o"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-4 form-group ">
                                                                                <input 
                                                                                    id="txtBloodGroup" 
                                                                                    name="txtBloodGroup" 
                                                                                    type="text" 
                                                                                    className="form-control"
                                                                                    placeholder="Blood Group"
                                                                                    value={memberDetails.BloodGroup || ''}
                                                                                    onChange={(event) => updateMemberDetails('BloodGroup', event.target.value)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-6 form-group ">
                                                                                <input 
                                                                                    id="txtRFID" 
                                                                                    name="txtRFID" 
                                                                                    type="text" 
                                                                                    className="form-control"
                                                                                    placeholder="RFID"
                                                                                    value={memberDetails.RFID || ''}
                                                                                    onChange={(event) => updateMemberDetails('RFID', event.target.value)} 
                                                                                />
                                                                            </div>
                                                                            <div className="col-6 form-group ">
                                                                                <input 
                                                                                    id="txtBagTag" 
                                                                                    name="txtBagTag" 
                                                                                    type="text" 
                                                                                    className="form-control"
                                                                                    placeholder="Bag Tag"
                                                                                    value={memberDetails.BagTag || ''}
                                                                                    onChange={(event) => updateMemberDetails('BagTag', event.target.value)} 
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="row" align='center'>
                                                                            <div className={`offset-4 ${editingXid == 0 || editingXid ? 'col-4' : 'col-3'} margin-b30`}>
                                                                                {editingXid == 0 || editingXid ? (
                                                                                    <div className="btn-group btn-block" role="group">
                                                                                        <button
                                                                                            id="btnAdd"
                                                                                            className="btn btn-brand btn-block margin-l30"
                                                                                            // style={{ maxWidth: '70%' }}
                                                                                            onClick={addMembersHandler}
                                                                                            >
                                                                                            Update Member
                                                                                        </button>
                                                                                        <button
                                                                                            style={{ maxWidth: '20%' }}
                                                                                            className="btn btn-secondary btn-block margin-0 padding-0"
                                                                                            onClick={() => {
                                                                                                setEditingXid(null);
                                                                                                setMemberDetails({});
                                                                                            }}
                                                                                        >
                                                                                            <i
                                                                                                className="la la-times"
                                                                                                style={{ fontSize: '1rem', marginLeft: '3px' }}
                                                                                            />
                                                                                        </button>
                                                                                    </div>
                                                                                    ) : (
                                                                                    <button
                                                                                        id="btnAdd"
                                                                                        className="btn btn-label-brand btn-block margin-l30"
                                                                                        onClick={addMembersHandler}
                                                                                        >
                                                                                        <i className="la la-plus" />
                                                                                        Add Member
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        {members && members.length > 0 &&
                                                                        (
                                                                            <div className="row">
                                                                                <div className="col-md-12">
                                                                                    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded">
                                                                                        <table className="kt-datatable__table table-striped">
                                                                                            <tbody className="kt-datatable__body" style={{ display: 'block' }}>
                                                                                                {members.map((m, i) => (
                                                                                                    <tr key={i} className="kt-datatable__row block-row">
                                                                                                        <td className="padding-l20 kt-datatable__cell text-clip">
                                                                                                            <div className="kt-user-card-v2">
                                                                                                                <div className="kt-user-card-v2__pic">
                                                                                                                    {m.Photo && m.Photo != '' ? 
                                                                                                                    <img src={URL.createObjectURL(m.Photo)} alt="user-profile" height={50} width={50} /> : 
                                                                                                                    <div className={`kt-badge kt-badge--xl kt-badge--${m.Category === 'Organization' ? 'success' : 'info'} `}> {m.FirstName.substring(0, 1)}</div>}
                                                                                                                </div>
                                                                                                                <div className="kt-user-card-v2__details text-clip">
                                                                                                                    <span className="kt-user-card-v2__name text-clip"> {`${m.FirstName} ${m.MiddleName || ''} ${m.LastName}`} </span> <span className="kt-user-card-v2__desc text-clip"> {m.Email} </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td width="15%" className="padding-l20 kt-datatable__cell text-clip">
                                                                                                            <span>
                                                                                                                {m.MemberType}
                                                                                                            </span>
                                                                                                        </td>
                                                                                                        <td width="15%" className="padding-l20 kt-datatable__cell text-clip">
                                                                                                            <span>{m.Mobile || ''}</span>
                                                                                                        </td>
                                                                                                        <td width="15%" className="padding-l20 kt-datatable__cell text-clip">
                                                                                                            <span>{m.DOB ?  moment(m.DOB).format('DD-MMM-YYYY') : ''}</span>
                                                                                                        </td>
                                                                                                        <td width="15%" className="text-right kt-datatable__cell text-clip">
                                                                                                            <button
                                                                                                                type="button"
                                                                                                                className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10"
                                                                                                                onClick={() => updateMemberHandler(i, m)}
                                                                                                                >
                                                                                                                <i className="flaticon2-edit"></i>
                                                                                                            </button>
                                                                                                            {i > 0 ? (
                                                                                                                <button
                                                                                                                type="button"
                                                                                                                onClick={() => removeMemberHandler(i)}
                                                                                                                className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10"
                                                                                                                >
                                                                                                                <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                                                                                </button>
                                                                                                            ) : (
                                                                                                                <span style={{ marginRight: '38px' }}></span>
                                                                                                            )}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                ))}
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                        <div id="wizard-2" className="kt-wizard-v3__content MembershipDetails" data-ktwizard-type="step-content" >
                                                            <div className="padding-lr10">
                                                                <form className="kt-form" id="add-userDetails-form">
                                                                    <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                                                        <div className="row">
                                                                            <div className="col-12 form-group">
                                                                                <div className="input-group date">
                                                                                    <input 
                                                                                        id="txtMembershipExpiresOn" 
                                                                                        name="txtMembershipExpiresOn" 
                                                                                        type="text" 
                                                                                        className="form-control date-input" 
                                                                                        readOnly={true} 
                                                                                        placeholder="Select Membership Expires On" 
                                                                                        value={userDetails.MembershipExpiresOn ? moment(userDetails.MembershipExpiresOn).format('DD-MM-YYYY') : ''} 
                                                                                    />
                                                                                    <div className="input-group-append">
                                                                                        <span className="input-group-text">
                                                                                            <i className="la la-calendar-check-o"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-4 form-group ">
                                                                                <input 
                                                                                    id="txtAadharCardNo" 
                                                                                    name="txtAadharCardNo" 
                                                                                    type="text" 
                                                                                    className="form-control"
                                                                                    placeholder="Aadhaar Card No"
                                                                                    value={userDetails.AadharCardNo || ''}
                                                                                    onChange={(event) => updateUsersDetails('AadharCardNo', event.target.value)} 
                                                                                />
                                                                            </div>
                                                                            <div className="col-4 form-group ">
                                                                                <input 
                                                                                    id="txtPanCardNo" 
                                                                                    name="txtPanCardNo" 
                                                                                    type="text" 
                                                                                    className="form-control"
                                                                                    placeholder="Pan Card No"
                                                                                    value={userDetails.PanCardNo || ''}
                                                                                    onChange={(event) => updateUsersDetails('PanCardNo', event.target.value)} 
                                                                                />
                                                                            </div>
                                                                            <div className="col-4 form-group ">
                                                                                <input 
                                                                                    id="txtGstNo" 
                                                                                    name="txtGstNo" 
                                                                                    type="text" 
                                                                                    className="form-control"
                                                                                    placeholder="GST No"
                                                                                    value={userDetails.GstNo || ''}
                                                                                    onChange={(event) => updateUsersDetails('GstNo', event.target.value)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-12 form-group ">
                                                                                <textarea 
                                                                                    id="txtAddress" 
                                                                                    name="txtAddress" 
                                                                                    className="form-control" 
                                                                                    rows="3"
                                                                                    placeholder="Address"
                                                                                    value={userDetails.Address || ''}
                                                                                    onChange={(event) => updateUsersDetails('Address', event.target.value)}
                                                                                ></textarea>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-4 form-group ">
                                                                                <input 
                                                                                    id="txtCountry"
                                                                                    name="txtMembershipNo " 
                                                                                    type="text" 
                                                                                    className="form-control"
                                                                                    placeholder='Country'
                                                                                    value={userDetails.Country  || ''}
                                                                                    onChange={(event) => updateUsersDetails('Country', event.target.value)} 
                                                                                />
                                                                            </div>
                                                                            <div className="col-4 form-group ">
                                                                                <input 
                                                                                    id="txtState"
                                                                                    name="txtState " 
                                                                                    type="text" 
                                                                                    className="form-control"
                                                                                    placeholder='State'
                                                                                    value={userDetails.State  || ''}
                                                                                    onChange={(event) => updateUsersDetails('State', event.target.value)} 
                                                                                />
                                                                            </div>
                                                                            <div className="col-4 form-group ">
                                                                                <input 
                                                                                    id="txtCity"
                                                                                    name="txtCity " 
                                                                                    type="text" 
                                                                                    className="form-control"
                                                                                    placeholder='City'
                                                                                    value={userDetails.City  || ''}
                                                                                    onChange={(event) => updateUsersDetails('City', event.target.value)} 
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-4 form-group ">
                                                                                <input 
                                                                                    id="txtPincode" 
                                                                                    name="txtPincode" 
                                                                                    type="text" 
                                                                                    className="form-control"
                                                                                    placeholder="Pincode"
                                                                                    value={userDetails.Pincode || ''}
                                                                                    onChange={(event) => updateUsersDetails('Pincode', event.target.value)} 
                                                                                />
                                                                            </div>
                                                                            <div className="col-4 form-group ">
                                                                                <input 
                                                                                    id="txtPhone" 
                                                                                    name="txtPhone" 
                                                                                    type="text" 
                                                                                    className="form-control"
                                                                                    placeholder="Phone"
                                                                                    value={userDetails.Phone || ''}
                                                                                    onChange={(event) => updateUsersDetails('Phone', event.target.value)} 
                                                                                />
                                                                            </div>
                                                                            <div className="col-4 form-group ">
                                                                                <input 
                                                                                    id="txtNominee" 
                                                                                    name="txtNominee" 
                                                                                    type="text" 
                                                                                    className="form-control"
                                                                                    placeholder="Nominee"
                                                                                    value={userDetails.Nominee || ''}
                                                                                    onChange={(event) => updateUsersDetails('Nominee', event.target.value)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-12 form-group ">
                                                                                <textarea 
                                                                                    id="txtRemark" 
                                                                                    name="txtRemark" 
                                                                                    className="form-control" 
                                                                                    rows="3"
                                                                                    placeholder="Remark"
                                                                                    value={userDetails.Remark || ''}
                                                                                    onChange={(event) => updateUsersDetails('Remark', event.target.value)}
                                                                                ></textarea>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row">
                                                                            <label className="col-form-label col-lg-6">Active</label>
                                                                            <div className="col-xl-6 col-lg-6">
                                                                                <span className="kt-switch kt-switch--icon">
                                                                                    <label>
                                                                                        <input 
                                                                                            id="chkActive" 
                                                                                            name="chkActive" 
                                                                                            type="checkbox"
                                                                                            defaultChecked={userDetails.Active == 'N' ? '' : 'checked'}
                                                                                            onChange={(event) => updateUsersDetails('Active', event.target.checked ? 'Y' : 'N')} 
                                                                                        />
                                                                                        <span></span>
                                                                                    </label>
                                                                                </span>
                                                                            </div>
                                                                        </div> 
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                        <div id="wizard-3" className="kt-wizard-v3__content Subscription" data-ktwizard-type="step-content" >
                                                            <div className="padding-lr10">
                                                                <div>
                                                                    {subscriptionValidity.length > 0 ? subscriptionValidity.map((subscription, index) => (
                                                                        <div className="kt-portlet" key={index} onClick={() => {setSubscriptionDetails({...subscription, Charge: Math.floor(subscription.Total / (1 + subscription.GST / 100)+ 0.5), GSTAmount:  Math.floor((subscription?.Total / (1 + subscription?.GST) * subscription?.GST) / 100)}) ,setShowSubModal(true)}}  style={{boxShadow: '0px 0px 13px 0px rgb(82 63 105 / 25%)'}} onMouseEnter={(e) => e.currentTarget.style.boxShadow = '0px 0px 20px 0px rgb(82 63 105 / 40%)'} onMouseLeave={(e) => e.currentTarget.style.boxShadow = '0px 0px 13px 0px rgb(82 63 105 / 25%)'}>
                                                                            <div className="kt-portlet__body">
                                                                                <div className="kt-section kt-section--space-md" style={{marginBottom: '2px'}}>
                                                                                    <div className="kt-widget24 kt-widget24--solid">
                                                                                        <div className="kt-widget24__details">
                                                                                            <div className="kt-widget24__info">
                                                                                                <a href="#" className="kt-widget24__title" title="Click to edit">
                                                                                                    {subscription.Name}
                                                                                                    &nbsp;&nbsp;&nbsp;
                                                                                                    <span className={`kt-badge kt-badge--${subscription.Status == "Active" ? 'success' : 'brand'}  kt-badge--inline kt-badge--pill padding-lr10`}>
                                                                                                        {subscription.Status}
                                                                                                    </span>
                                                                                                </a>
                                                                                                <span className="kt-widget24__desc">
                                                                                                    {subscription.Code}
                                                                                                </span><br/>
                                                                                                <span className="kt-widget24__desc">
                                                                                                    {subscription.Description}
                                                                                                </span>
                                                                                            </div>
                                                                                            <span className={`kt-widget24__stats kt-font-${subscription.Status == "Active" ? 'success' : 'brand'} `}>
                                                                                                ₹{subscription.Total}
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className="progress progress--sm">
                                                                                            <div className={`progress-bar kt-bg-${subscription.Status == "Active" ? 'success' : 'brand'}`} role="progressbar" style={{ width: calculateProgress(subscription.SubscriptionStartsFrom, subscription.SubscriptionExpiresOn) }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                                                        </div>
                                                                                        <div className="kt-widget24__action">
                                                                                            <span className="kt-widget24__change">
                                                                                                {moment(subscription.SubscriptionStartsFrom).format('DD-MMM-YYYY')}
                                                                                            </span>
                                                                                            <span className="kt-widget24__number">
                                                                                                {moment(subscription.SubscriptionExpiresOn).format('DD-MMM-YYYY')}
                                                                                            </span>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    
                                                                    )): (
                                                                        <div style={{textAlign: 'center'}} className="padding-t20">
                                                                            <img src={subscription_expired} alt="subscription-expired" height="440" width="550" style={{opacity:'0.6', color: 'grey'}}></img>
                                                                            <h5>No Subscription</h5>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div style={{textAlign: 'center'}} className="padding-tb30">
                                                                    <button
                                                                        onClick={() => {setSubscriptionDetails({}), setShowSubModal(true)}}
                                                                        type="button"
                                                                        className="btn btn-label-brand margin-0 margin-r5"
                                                                        >                 
                                                                    <i className="la la-plus"></i>Add Subscription
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div id="wizard-4" className="kt-wizard-v3__content Documents" data-ktwizard-type="step-content" >
                                                            <div className="padding-lr10">
                                                                <form className="kt-form" id="add-document-form">
                                                                    <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                                                        <div className="row">
                                                                            <div className="col-12 form-group ">
                                                                                <Select 
                                                                                    id="ddlDocumentType" 
                                                                                    name="ddlDocumentType"
                                                                                    className="multi-custom"
                                                                                    placeholder="Select Document Type"
                                                                                    value={documentDetails && documentTypeOption.find((m) => m.value == documentDetails.DocumentType )  || ''}
                                                                                    onChange={(event) => updateDocumentDetails('DocumentType', event.value)}
                                                                                    options={documentTypeOption}

                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-12 form-group ">
                                                                                <textarea 
                                                                                    id="txtRemark" 
                                                                                    name="txtRemark" 
                                                                                    className="form-control" 
                                                                                    rows="3"
                                                                                    placeholder="Remark"
                                                                                    value={documentDetails.Remark || ''}
                                                                                    onChange={(event) => updateDocumentDetails('Remark', event.target.value)}
                                                                                ></textarea>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row">
                                                                            <div className="col-12">
                                                                                {memberDocument ? (
                                                                                    <div className="kt-notification  margin-b0" style={{backgroundColor: '#f7f8fa' }} > 
                                                                                        <a className="kt-notification__item " >
                                                                                            <div className="kt-notification__item-icon margin-r10" >
                                                                                                <div className="kt-avatar kt-avatar--outline kt-avatar--changed" >
                                                                                                    <img height="30px" width="30px" 
                                                                                                        src={helper.getFileLogo(memberDocument.name)} 
                                                                                                    />            
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="kt-notification__item-details"  >
                                                                                                <div className="kt-notification__item-title kt-font-bold">
                                                                                                    {documentDetails.DocumentType || 'Document'}
                                                                                                </div>
                                                                                            </div>
                                                                                            <button 
                                                                                                type="button"
                                                                                                style={{width: "40px", height:"40px"}}                    
                                                                                                onClick={() => setMemberDocument(null)}
                                                                                                className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">
                                                                                                <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                                                            </button>
                                                                                        </a>
                                                                                    </div>  
                                                                                ):((step == "Documents") &&
                                                                                    <Dropzone fileHandler={documentHandler} />
                                                                                )}
                                                                            </div>
                                                                        </div> 
                                                                        <div className="form-group row">
                                                                            <label className="col-form-label col-lg-6">Show On App</label>
                                                                            <div className="col-xl-6 col-lg-6">
                                                                                <span className="kt-switch kt-switch--icon">
                                                                                    <label>
                                                                                        <input 
                                                                                            id="chkActive" 
                                                                                            name="chkActive" 
                                                                                            type="checkbox"
                                                                                            defaultChecked={documentDetails.ShowOnApp == 'N' ? '' : 'checked'}
                                                                                            onChange={(event) => updateDocumentDetails('ShowOnApp', event.target.checked ? 'Y' : 'N')} 
                                                                                        />
                                                                                        <span></span>
                                                                                    </label>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row" align='center'>
                                                                            <div className="offset-4 col-3 margin-b30">
                                                                                {editingDocumentXid == 0 || editingDocumentXid ? (
                                                                                    <div className="btn-group btn-block" role="group">
                                                                                        <button
                                                                                            id="btnAdd"
                                                                                            className="btn btn-brand btn-block margin-l30"
                                                                                            style={{ maxWidth: '70%' }}
                                                                                            onClick={addDocumntHandler}
                                                                                            >
                                                                                            Update Document
                                                                                        </button>
                                                                                        <button
                                                                                            style={{ maxWidth: '30%' }}
                                                                                            className="btn btn-secondary btn-block margin-0 padding-0"
                                                                                            onClick={() => {
                                                                                                setEditingDocumentXid(null);
                                                                                                setDocumentDetails({ShowOnApp: 'Y'});
                                                                                            }}
                                                                                        >
                                                                                            <i
                                                                                                className="la la-times"
                                                                                                style={{ fontSize: '1rem', marginLeft: '3px' }}
                                                                                            />
                                                                                        </button>
                                                                                    </div>
                                                                                    ) : (
                                                                                    <button
                                                                                        id="btnAdd"
                                                                                        className="btn btn-label-brand btn-block margin-l30"
                                                                                        onClick={addDocumntHandler}
                                                                                        >
                                                                                        <i className="la la-plus" />
                                                                                        Add Document
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                        </div> 
                                                                        <div className="row margin-b20">
                                                                            <div className="col-12">
                                                                                {memberDocumentList.length > 0 &&  memberDocumentList && (
                                                                                    <div className="kt-notification  margin-b0" style={{backgroundColor: '#f7f8fa' }} > 
                                                                                        {memberDocumentList.map((m, i) => (
                                                                                            <div key={i} >
                                                                                                <a className="kt-notification__item padding-10" >
                                                                                                    <div className="kt-notification__item-icon margin-r10" >
                                                                                                        <div className="kt-avatar kt-avatar--outline kt-avatar--changed" >
                                                                                                            <img height="30px" width="30px" 
                                                                                                                src={helper.getFileLogo(m.FileName.name)} 
                                                                                                            />   
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="kt-notification__item-details"  >
                                                                                                        <div className="kt-notification__item-title kt-font-bold">
                                                                                                            {m.DocumentType}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <button 
                                                                                                        type="button"
                                                                                                        className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10"
                                                                                                        data-toggle="kt-popover"
                                                                                                        data-trigger="hover"
                                                                                                        data-placement="left"
                                                                                                        data-content="Edit Document"                
                                                                                                        onClick={() => updateDocumentHandler(m, i)}
                                                                                                    >  
                                                                                                        <i className="flaticon2-edit"></i>
                                                                                                    </button>
                                                                                                    <button 
                                                                                                        type="button"
                                                                                                        style={{width: "40px", height:"40px"}}                    
                                                                                                        onClick={() => removeDocumentHandler(i)}
                                                                                                        className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">
                                                                                                        <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                                                                    </button>

                                                                                                </a>
                                                                                                <hr className="margin-0"/>
                                                                                            </div>
                                                                                        ))}
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="kt-portlet__foot">
                                            <div className="kt-form__actions">
                                                <div className="row">
                                                    <div className="col-12 "  align="center">
                                                        <button id="btnCancel" type="button" className="btn btn-secondary margin-l10"  onClick={closeDrawer}>Cancel</button>

                                                        {step != 'AddMember' &&  (  <button id="btnPrv" type="button" className="btn btn-warning d-inline-block margin-l5" onClick={onPrevHandler}>Prev</button>)}
                                                        
                                                        {step != 'Documents' ? (
                                                            <button id="btnSave" type="button" className="btn btn-brand d-inline-block margin-l5" onClick={() => onNextHandler()}>Next</button>
                                                        ):(
                                                            <button id="btnSave" type="button" className="btn btn-brand d-inline-block margin-l5" onClick={addUserDetailsHandler}>{memberDocumentList.length > 0 ? 'Save' : 'Skip & Save'}</button>
                                                        )}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                    :
                                    <>
                                        <div className="row">
                                            <div className={`col-${userDetails.Category == "Organization" ? '6' : '12'}`}> 
                                                <div className="kt-portlet">
                                                    <div className="kt-portlet__body">
                                                        <div className="kt-widget kt-widget--user-profile-3">
                                                            <div className="kt-widget__top">
                                                                {userDetails.Photo && userDetails.Photo != '' ? 
                                                                    <img className="kt-widget__pic kt-widget__pic" src={userDetails.Photo} alt="user-profile"/> :
                                                                    <div
                                                                        onClick={() => setShowUserDetails(userDetails)}
                                                                        className={`kt-widget__pic kt-widget__pic--${
                                                                            userDetails.Category === 'Organization'
                                                                            ? 'success'
                                                                            : 'brand'
                                                                        } kt-font-${
                                                                            userDetails.Category === 'Organization'
                                                                            ? 'success'
                                                                            : 'brand'
                                                                        } kt-font-boldest kt-hidden-`}>
                                                                        {userDetails.FirstName.slice(0, 1) +
                                                                            userDetails.LastName.slice(0, 1)}
                                                                    </div>
                                                                }   
                                                                <div className="kt-widget__content">
                                                                    <div className="kt-widget__head">
                                                                        <span className="kt-widget__username"  onClick={() => setShowUserDetails(userDetails)}>
                                                                            {userDetails.FirstName + ' ' + userDetails.MiddleName + ' ' + userDetails.LastName}{' '}
                                                                            &nbsp;&nbsp;&nbsp;
                                                                            <span className={`kt-badge kt-badge--${userDetails.MembershipType == 'Golf Membership' ? 'success' : 'info'} kt-badge--inline kt-badge--pill padding-lr10`}>
                                                                                {userDetails.MembershipType}
                                                                            </span>
                                                                        </span>
                                                                        <div className="kt-widget__action">
                                                                            <span className="dropdown">
                                                                                <a
                                                                                    className="btn btn-sm btn-clean btn-icon btn-icon-md"
                                                                                    data-toggle="dropdown"
                                                                                    aria-expanded="true">
                                                                                    <i className="flaticon-more-1"></i>
                                                                                </a>
                                                                                <OverflowMenu
                                                                                    onEditClick={() =>
                                                                                        {setEditUser(true)}
                                                                                    }
                                                                                />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="kt-widget__subhead padding-t0"  onClick={() => setShowUserDetails(userDetails)}>
                                                                        <span className="padding-r10">
                                                                            <i className="flaticon2-calendar-3"></i>
                                                                            {userDetails.Category} 
                                                                        </span>
                                                                        <span className="padding-r10">
                                                                            <i className="flaticon2-user"></i>
                                                                            {userDetails.MembershipNo}
                                                                        </span>
                                                                        <br></br>
                                                                        {userDetails.Mobile && (
                                                                            <span className="padding-r10">
                                                                            <i className="flaticon2-phone"></i>
                                                                            {userDetails.Mobile}
                                                                            </span>
                                                                        )}
                                                                        {userDetails.Email && (
                                                                            <span className="padding-r10">
                                                                                <i className="flaticon2-new-email"></i>
                                                                                {userDetails.Email}
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {userDetails.Category == "Organization" && (
                                                <div className="col-md-6">
                                                    <div className="kt-portlet">
                                                        <div className="kt-portlet__body">
                                                            <div className="kt-widget kt-widget--user-profile-3">
                                                                <div className="kt-widget__top">
                                                                    <div
                                                                        className={`kt-widget__pic kt-widget__pic--${
                                                                            userDetails.Category === 'Organization'
                                                                            ? 'success'
                                                                            : 'brand'
                                                                        } kt-font-${
                                                                            userDetails.Category === 'Organization'
                                                                            ? 'success'
                                                                            : 'brand'
                                                                        } kt-font-boldest kt-hidden-`}>
                                                                        {userDetails.CompanyName.slice(0, 1)}
                                                                    </div>
                                                                    <div className="kt-widget__content">
                                                                        <div className="kt-widget__head">
                                                                            <span className="kt-widget__username">
                                                                                {userDetails.CompanyName
                                                                                }{' '}
                                                                            </span>
                                                                            <div className="kt-widget__action">
                                                                                <span className="dropdown">
                                                                                    <a
                                                                                        className="btn btn-sm btn-clean btn-icon btn-icon-md"
                                                                                        data-toggle="dropdown"
                                                                                        aria-expanded="true">
                                                                                    </a>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="kt-widget__subhead padding-t0">
                                                                            <span className="padding-r10">
                                                                                <i className="flaticon2-calendar-3"></i>
                                                                                {userDetails.Category}
                                                                            </span>
                                                                            <br></br>
                                                                            <span className="padding-r10">
                                                                                <i className="flaticon2-new-email"></i>
                                                                                {userDetails.Email}
                                                                            </span>
                                                                            {userDetails.Mobile && (
                                                                                <span className="padding-r10">
                                                                                    <i className="flaticon2-phone"></i>
                                                                                    {userDetails.Mobile}
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="kt-portlet kt-portlet--mobile">
                                            <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                                                <div className="kt-portlet__head-label">
                                                    <nav className="file-nav navbar navbar-top">
                                                        <a className={userNavState === "Members" ? "top-nav-active" : "" } onClick={() => setUserNavState("Members")}  >  
                                                            Members
                                                        </a>
                                                        <a className={userNavState === "Subscription" ? "top-nav-active" : "" } onClick={() => setUserNavState("Subscription")}  >  
                                                            Subscription
                                                        </a>
                                                        <a className={userNavState === "Documents" ? "top-nav-active" : "" } onClick={() => setUserNavState("Documents")}  >  
                                                            Documents
                                                        </a>
                                                    </nav>
                                                </div>
                                                <div className="kt-portlet__head-toolbar">
                                                    <div className="kt-portlet__head-wrapper">
                                                        <div className="kt-portlet__head-actions">
                                                            {userNavState === 'Members' && (
                                                                <button className="btn btn-label-brand btn-block margin-0"
                                                                    onClick={(e) => {e.preventDefault(), setAddMember(true)}}
                                                                >
                                                                    <i className="la la-plus"></i>
                                                                    Add Member
                                                                </button>
                                                            )}
                                                            {userNavState === 'Subscription' && (
                                                                <button className="btn btn-label-brand btn-block margin-0"
                                                                    onClick={(e) => {e.preventDefault(), setShowSubModal(true)}}
                                                                >
                                                                    <i className="la la-plus"></i>
                                                                    Add Subscription
                                                                </button>
                                                            )}
                                                            {userNavState === 'Documents' && (
                                                                <button className="btn btn-label-brand btn-block margin-0"
                                                                    onClick={(e) => {e.preventDefault(), setAddDocument(true)}}
                                                                >
                                                                    <i className="la la-plus"></i>
                                                                    Add Document
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="kt-portlet__body padding-t0">
                                                {(userNavState === 'Members') && (
                                                    <Member
                                                        members = {usersList.filter(f => f.GroupID == userDetails.GroupID)}
                                                        updateUserHandler={() => updateUserHandler()}
                                                        setShowUserDetails={setShowUserDetails}
                                                        setMemberDetails={setMemberDetails}

                                                    />
                                                )}
                                                {(userNavState === 'Subscription') && (
                                                    <UserSubscription
                                                        // refresh={documentRefresh}
                                                        setEditDocument={setEditDocument}
                                                        subscriptionValidity={subscriptionValidity}                                      
                                                        setSubscriptionDetails={setSubscriptionDetails}                                      
                                                        setShowSubModal={setShowSubModal}                                      
                                                    />
                                                )}
                                                {(userNavState === 'Documents') && (
                                                    <MemberDocuments
                                                        refresh={documentRefresh}
                                                        setEditDocument={setEditDocument}
                                                        GroupID={userDetails.GroupID}                                      
                                                    />
                                                )}

                                            </div>
                                        </div>
                                    </>
                                }
                            </RightSideDetails>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {showUserDetails && (
                <UserDetails
                    show={showUserDetails}
                    onDismissModal={setShowUserDetails}                   
                    user={showUserDetails}                   
                />
            )}
            {addMember && (
                <AddMember
                    show={addMember}
                    members = {usersList.filter(f => f.GroupID == userDetails.GroupID)}
                    refetchUsers={() => refetchUsers()}
                    onDismissModal={setAddMember}                   
                    GroupID={userDetails.GroupID}                   
                    memberDetails={memberDetails || ''}
                    setMemberDetails={setMemberDetails}                   
                />
            )}
            {(addDocument || editDocument) && (
                <AddDocument
                    show={addDocument}
                    refetchDocuments={() => refetchDocuments()}
                    onDismissModal={setAddDocument}
                    GroupID={userDetails.GroupID}  
                    editDocument={editDocument || ''}                                    
                    setEditDocument={setEditDocument}                                    
                />
            )}
            {editUser && (
                <EditUser
                    show={editUser}
                    refetchUsers={() => refetchUsers()}
                    onDismissModal={setEditUser}                          
                    userDetails={userDetails}                          
                />
            )}
            {cropperData && (
                <ImageCropper
                    show           = { cropperData }
                    header         = { cropperData.header }   
                    imageRatio     = { cropperData.imageRatio }
                    dataHandler    = { cropperData.dataHandler }
                    onDismissModal = {() => setCropperData(null) }
                />
            )}
            {showSubModal && (
                <UpdateSubscriptionModal
                    show={showSubModal}
                    onDismissModal={setShowSubModal}
                    subscriptionDetails={subscriptionDetails}
                    setSubscriptionDetails={setSubscriptionDetails}
                    subscriptionValidity={subscriptionValidity}
                    userDetails={userDetails}
                    from={userDetails?.GroupID ? '#edit-user-modal' : ''}
                    vIndex={subscriptionValidity.length + 2}
                    refetchValidity={() => refetchValidity()}
                />
            )}
        </>
     )
}
export default Users;