import { useEffect, useState } from "react";
import AddMonthlyPaymentFile from "./addMonthlyPaymentFile";
import { deleteMonthlyPaymentByID, getMonthlyPaymentByDate } from "../api/paymentAPI";
import Swal from 'sweetalert2';
import swal from 'sweetalert2';
import EditMemberMonthlyPayment from "./editMemberMonthlyPayment";
import moment from "moment";
import Select from 'react-select';
import defaultUserImg from '../../assets/images/user.jpg'


const MonthlyPayment = (props) => {

    const [selectedMonth,             setSelectedMonth]            = useState(moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'));
    const [addFile,                   setAddFile]                  = useState(false);
    const [monthlyPaymentReport,      setMonthlyPaymentReport]     = useState([]);
    const [isInit,                    setIsInit]                   = useState(true);
    const [monthlyPaymentTable,       setmonthlyPaymentTable]      = useState(null);
    const [refresh,                   setRefresh]                  = useState(false);
    const [memberDetails,             setMemberDetails]            = useState({});
    const [status,                    setStatus]                   = useState([{ value:  'All', label: 'All'},{ value:  'Paid', label: 'Paid'},{ value:  'Pending', label: 'Pending'}]);
    const [statusType,                setStatusType]               = useState(status[0].value);


    useEffect(() => {
        props.title('Monthly Report','monthly-payment')
        $('#txtDate').datepicker({
            format: 'MM yyyy',
            startView: "months", 
            minViewMode: "months",
            endDate: new Date(new Date().getFullYear(), new Date().getMonth() - 1)
        });  
        $('#txtDate').on('change', function (event) {
            setSelectedMonth(moment(event.target.value).format('YYYY-MM-DD'))
        }); 
        
    },[]);

    useEffect(() => {
        if(selectedMonth || refresh){
            fetchMonthlyPaymentReport(selectedMonth);
        };
    },[selectedMonth, refresh]);

    useEffect(() => {       
        if (!isInit) {
            fillDataTable();
        };
    }, [monthlyPaymentReport]);

    useEffect(() => {
        if(selectedMonth && !isInit && statusType){
            $('#tblMonthlyPaymentReport').KTDatatable().destroy(); 
            fillDataTable();
        };
    },[selectedMonth, statusType])

    const refetchReport = () => setRefresh((r) => !r);

    function exportSheet(type) {
        monthlyPaymentTable.update({
            headers: true,
            formats: ["xlsx", "csv"],
            filename: `${moment().format("DD-MM-YYYY")} - Monthly Payment Sheet`, // pass in a new set of properties
            columns: "Email"
 
        });

        if (type == 'xlsx') {
            $('.button-default.xlsx').click();
        } else {
            $('.button-default.csv').click();
        }
        $('.tableexport-caption').addClass('hidden');
    };

    const fetchMonthlyPaymentReport = async() => {
        try {
            swal.fire({
                titleText: 'Loading',
                text: 'Please Wait...',
                showConfirmButton: false,
                onOpen: () => {
                    swal.showLoading();
                }
            });
            const response = await getMonthlyPaymentByDate(selectedMonth);
            isInit ? setIsInit(false) : $('#tblMonthlyPaymentReport').KTDatatable().destroy(); 
            setMonthlyPaymentReport(response)
            swal.close();
        } catch (err) {
            Swal.fire({
                icon      : 'error',
                titleText : err.message
            })  
        }
    }

    function fillDataTable() {
        var ReportList = [];

        if (statusType == 'All') {
            ReportList = monthlyPaymentReport.MonthlyPaymentList;
        }else{
            ReportList = monthlyPaymentReport.MonthlyPaymentList.filter(i => i.Status == statusType);

        }
        const dataTable = $('#tblMonthlyPaymentReport').KTDatatable({
            data: {
                saveState: false,
                source: ReportList,
            },
            layout: {
                scroll: false,
                footer: false,
            },
            sortable: false,
            pagination: false,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'Name',
                    title: 'Member',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                    <div class="kt-user-card-v2__pic">
                                    ${(row.Photo && row.Photo != '') ? `<img src="${row.Photo}" alt="user-profile" height="50" width="50">` : `<img src="${defaultUserImg}" alt="user-profile" height="50" width="50">`}
                                    </div>
                                    <div class="kt-user-card-v2__details text-clip">
                                        <span class="kt-user-card-v2__name text-clip"> ${row.Name} </span> <span class="kt-user-card-v2__desc text-clip"> ${row.MembershipNo} </span>
                                    </div>
                                </div>`
                    },
                    width: 180,

                },
                {
                    field: ' ',
                    title: 'Contact',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                    <div class="kt-user-card-v2__details text-clip">
                                        <span class="kt-user-card-v2__name text-clip"> ${row.Mobile} </span> 
                                        <span class="kt-user-card-v2__desc text-clip"> ${row.Email} </span>
                                    </div>
                                </div>`
                    },
                    width: 180,

                },
                {
                    field: 'MembershipType',
                    title: 'Membership Type',
                    width: 130,
                },
                {
                    field: 'BillNumber',
                    title: 'Bill Number',
                    width: 85,
                },
                {
                    field: 'BillAmount',
                    title: 'Bill Amount',
                    width: 100,
                    template: function (row) {
                        return `<strong style="font-weight: 600"> ₹ ${row.BillAmount}</strong>`
                    }
                },
                {
                    field: 'TransactionDate',
                    title: 'Transaction Date',
                    width: 120,
                    template: function (row) {
                        return row?.TransactionDate && moment(row.TransactionDate).format('DD-MMM-YYYY');
                    }
                    
                },
                {
                    field: 'Status',
                    title: 'Status',
                    width: 80,
                    template: function (row) {
                        if (row.Status == 'Pending') {
                            return '<div><span class="btn btn-bold btn-font-sm  btn-label-warning">' + 'Pending' + '</span></div>';
                        } else {
                            return '<div><span class="btn btn-bold btn-font-sm  btn-label-success" style="padding-left: 9px">' + 'Paid' + '</span></div>';
                        }
                    }
                },
                {
                    field: '',
                    title: '',
                    width: 60,
                    template: function (row) {

                        if (row.Status == "Pending" || row.Status == null) {
                            return showEditButton(row)
                        } else {
                            return `<center><button type="button"  class="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs btn-invoice" data-toggle="kt-popover"  data-trigger="hover click" data-placement="left" data-content="View Invoice"><i class="fa fa-file-alt"></i></button></center>`
                        }
                    }
                },
                
            ]
            ,
            rows: {
                autoHide: false,
            }
        });
        Config.Core.InitPopover();

        function showEditButton(row) {
            var optString = '';

            const isEdit = moment(selectedMonth).format("YYYY-MM") > moment().subtract(4, 'month').format("YYYY-MM")

            optString += '<center>'

            if (isEdit) optString += `<button type="button"  class="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs btn-edit"><i class="flaticon2-edit"></i></button>&nbsp;<button type="button"  class="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs btn-delete"><i class="flaticon2-rubbish-bin-delete-button"></i></button>`

            optString += '</center>'

            return optString;
        }

        dataTable.on('click', '.btn-edit', function (ss) {///edit
            const index = $(ss.currentTarget).parents('tr').index();
               
            if (dataTable.dataSet && dataTable.dataSet.length > 0 && dataTable.dataSet[index]) {
                editHandler(dataTable.dataSet[index])
            } 
        });

        dataTable.on('click', '.btn-delete', function (ss) {///delete
            const index = $(ss.currentTarget).parents('tr').index();
               
            if (dataTable.dataSet && dataTable.dataSet.length > 0 && dataTable.dataSet[index]) {
                deleteHandler(dataTable.dataSet[index].ID)
            } 
        });

        dataTable.on('click', '.btn-invoice', function (ss) {  
            const index = $(ss.currentTarget).parents('tr').index();
            if (dataTable.dataSet && dataTable.dataSet.length > 0 && dataTable.dataSet[index]) {
                window.open(`https://demo.clubez.in/invoice?id=${dataTable.dataSet[index].PaymentOrderID}`, '_blank', 'noreferrer');
            } 
        });

        // export to excel
        const monthlyPaymentTable = $('.kt-datatable__table').tableExport({
            formats: ["xlsx", "csv"],
            headers: true,
            filename: `${moment().format("DD-MM-YYYY")} - Sports Report`,
        });
        setmonthlyPaymentTable(monthlyPaymentTable);
        $('.tableexport-caption').addClass('hidden');
        
    };
    
    const editHandler = (obj) => {
        setMemberDetails(obj)
    };

    const deleteHandler = async(id) => {   
        try {
            const swalResult = await swal.fire({
                title               : 'Are you sure?',
                text                : "You won't be able to revert this!",
                icon                : 'warning',
                showCancelButton    : true,
                buttonsStyling      : false,
                reverseButtons      : true,
                showLoaderOnConfirm : true,
                confirmButtonClass  : 'btn btn-brand',
                cancelButtonClass   : 'btn btn-secondary',
                confirmButtonText   : 'Delete',
                preConfirm: async () => {
                  try {
                     await deleteMonthlyPaymentByID(id)  
                } catch (err) {
                    swal.fire({
                      icon                : 'error',
                      titleText           : 'Error!',
                      text                : err.message,
                      buttonsStyling      : false,
                      confirmButtonClass  : 'btn btn-brand',
                    });
                    return;
                  }
                },
            });
            if (!swalResult.value) return;
            swal.fire({
                icon: 'success',
                titleText: 'Member Deleted Successfully!',
                showConfirmButton: false,
                timer: 1500,
            });
            refetchReport();
        } catch (err) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    };

    return(
        <>  
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content">
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet">
                        <div className="kt-portlet__body padding-t20 padding-b0">
                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                <div className="row">
                                    <label className="col-form-label col-sm-1 text-right">Month</label>
                                    <div className="form-group col-sm-2">
                                        <div className="input-group date">
                                             <input 
                                                id="txtDate" 
                                                name="txtDate" 
                                                readOnly
                                                type="text" 
                                                className="form-control" 
                                                defaultValue={moment(selectedMonth, 'YYYY-MM-DD').format('MMMM YYYY') || ''} 
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <i className="la la-calendar-check-o"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <label className="col-form-label col-sm-1 text-right">Status</label>
                                    <div className="form-group col-sm-2 pnlStatus ">
                                        <div >
                                            <Select 
                                                id="ddlStatus" 
                                                name="ddlStatus "
                                                className="multi-custom "
                                                defaultValue={status.find((m) => m.value[0] )  || ''}
                                                onChange={(event) => setStatusType(event.value)}
                                                options={status}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-1">
                                    </div>
                                    <div className="form-group col-sm-2">
                                        <div className="input-group">
                                            <div className="kt-input-icon kt-input-icon--left">
                                                <input 
                                                    type="text" 
                                                    className="form-control clientSearch" 
                                                    placeholder="Search Member..." 
                                                    id="generalSearch" 
                                                />
                                                <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-1">
                                        <div className="dropdown">
                                            <button className="btn btn-label-brand dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Export
                                            </button>
                                            <div className="dropdown-menu " aria-labelledby="dropdownMenuButton" x-placement="bottom-start" style={{position: 'absolute', willChange: 'transform', top: '0px', left: '0px', transform: 'translate3d(0px, 38px, 0px)'}}>
                                                <button 
                                                    id="btn-export-excel" 
                                                    type="button" 
                                                    className="dropdown-item"
                                                    onClick={() => exportSheet('xlsx')}

                                                > Excel 
                                                </button>
                                                <button 
                                                    id="btn-export-csv" 
                                                    type="button" 
                                                    className="dropdown-item"
                                                    onClick={() => exportSheet('csv')}
                                                >  Csv </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div  className="form-group col-sm-2 pnlStatus ">
                                        {moment(selectedMonth).format("YYYY-MM") > moment().subtract(4, 'month').format("YYYY-MM") && (
                                            <button className="btn btn-label-brand btn-block margin-0"
                                                onClick={(e) => {e.preventDefault(), setAddFile(true)}}
                                            >
                                                <i className="la la-upload"></i>
                                                Upload File
                                            </button>
                                        )}
                                    </div>
                                </div>
                                <div className="row monthly-payemnt-amount-box" style={{color: '#535354', fontSize: '14px'}}>
                                    <label className="col-2"><span style={{fontWeight: '400'}}>Total</span><strong style={{fontWeight: '600'}}> :  ₹ {monthlyPaymentReport.Total}</strong>  </label>
                                    <label className="col-8"><span style={{fontWeight: '400'}}>Paid</span><strong style={{fontWeight: '600'}}> :  ₹ {monthlyPaymentReport.Paid}</strong>  </label>
                                    <label className="col-2"><span style={{fontWeight: '400'}}>Remaining</span><strong style={{fontWeight: '600'}}> :  ₹ {monthlyPaymentReport.Remaining}</strong>  </label>
                                </div>
                            </div>
                            <div id="tblMonthlyPaymentReport" className="table table-bordered table-hover custom-datatable"></div>
                        </div>
                    </div>
                </div>
            </div>
            {addFile && (
                <AddMonthlyPaymentFile
                    show={addFile}
                    onDismissModal={setAddFile}
                    selectedMonth={selectedMonth}
                    refetchReport={() => refetchReport()}
                />
            )}  
            {Object.keys(memberDetails).length > 0 && (
                <EditMemberMonthlyPayment
                    show={memberDetails}
                    onDismissModal={setMemberDetails}
                    memberDetails={memberDetails}
                    setMemberDetails={setMemberDetails}
                    refetchReport={() => refetchReport()}
                />
            )}
        </>
    )
}

export default MonthlyPayment;