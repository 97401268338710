import { useEffect, useState } from "react";
import ic_check_on from '../../assets/images/ic_check_on.png'
import RightSideDetails from "../global/rightSideDetails";
import ImageCropper from "../global/imageCropper";
import { getGuestAll, getMobileCode, postGuest, sendGuestOTP } from "../api/usersAPI";
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import Select from 'react-select';
import { uploadPublicFile } from "../api/fileAPI";


const Guest = (props) => {

    const [rightSideDetails,           setRightSideDetails]           = useState(false);
    const [guestList,                  setGuestList]                  = useState([]);
    const [mobileCodeList,             setMobileCodeList]             = useState([]);
    const [guest,                      setGuest]                      = useState({Active: 'Y'});
    const [isInit,                     setIsInit]                     = useState(true);
    const [otpModal,                   setOtpModal]                   = useState(false);
    const [otp,                        setOtp]                        = useState();
    const [sessionId,                  setSessionId]                  = useState();

    //<--- Cropper -->
    const [guestPhotoBlob,             setGuestPhotoBlob]             = useState(null);
    const [cropperData,                setCropperData]                = useState(null);


    useEffect(() => {        
        props.title('Guest', 'guest')
        fetchGuest();
        fetchMobileCode();
    },[])

    useEffect(() => {
        if(rightSideDetails){
            Config.Core.OpenDrawer()
        }else{
            setOtpModal(false)
            setGuest({Active: 'Y'})
            setGuestPhotoBlob(null)
        }
    },[rightSideDetails])

    useEffect(() => {       
        if (!isInit) {
            fillDataTable();        
        }
    }, [guestList]);

    const fetchGuest = async() =>{
        try {
            const guests = await getGuestAll()   
            isInit ? setIsInit(false) : $('#tblGuest').KTDatatable().destroy(); 
            setGuestList(guests);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    function fillDataTable() {

        const dataTable = $('#tblGuest').KTDatatable({
            data: {
                saveState: false,
                source: guestList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'Name',
                    title: 'Name',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                    <div class="kt-user-card-v2__pic">
                                    ${(row.Photo && row.Photo != '') ? `<img src="${row.Photo}" alt="event-thumbail" >` : ` <div class="kt-badge kt-badge--xl kt-badge--info">  ${row.Name.substring(0, 1)}</div>`}
                                </div>
                                    <div class="kt-user-card-v2__details text-clip">
                                        <span class="kt-user-card-v2__name text-clip"> ${row.Name} 
                                        </span> <span class="kt-user-card-v2__desc text-clip"> ${row.Mobile} </span>
                                    </div>
                               </div>`
                    }
                },
                {
                    field: 'Email',
                    title: 'Email',
                },
                {
                    field: 'IGUNumber',
                    title: 'IGUNumber',
                },
                {
                    field: 'Verified',
                    title: 'Verified',
                    template: function (row) {
                        return row.IsVerified == "N" ? '<span class="padding-l10">  -  </span>' : `<img src="${ic_check_on}" height="24"/>`
                    },

                },
                {
                    field: 'Active',
                    title: 'Active',
                    template: function (row) {
                        return row.Active == 'N' ? '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10"> No </span>' : '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                    },
                    width: 50,
                },
            ]
        });

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblGuest .kt-datatable__table').addClass('overflow-x-auto');
        
        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);
            if(dataTable.dataSet[index]){
                setGuest(dataTable.dataSet[index])
                setRightSideDetails(true)
                setOtpModal(false)
            }
        });
    }

    const fetchMobileCode = async() =>{
        try {
            const code = await getMobileCode();
            setMobileCodeList(code)
        } catch (err) {
            swal.fire({
                icon      : 'error',
                titleText : err.message
            })  
        }
    }

    const updateGuestDetails = (field, value) => {   
        setGuest((d) => {
            return { ...d, [field]: value };
        });
    };

    const otpHandler = async() => {
        
        var form = $('#add-guest-form');
        
        form.validate({
            rules: {
                txtName: {
                    required: true
                },          
                txtMobile: {
                    required: true,
                    number: true,
                }        
            },
            messages: {
                txtName: {
                    required: "Please enter name"
                },
                txtMobile: {
                    required: "Please enter mobile",
                }
            }
        });
        
        if (!form.valid()) return;
        
        swal.fire({
            titleText           : 'Loading',
            text                : 'Please Wait...',
            showConfirmButton   : false,
            allowOutsideClick   : false,
            onOpen: () => {
                swal.showLoading();
            }
        });
        try {
            const session = await sendGuestOTP(guest.Mobile, (guest.ID || 0))
            setOtpModal(true)
            setSessionId(session)
            swal.close();
            
        } catch (err) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return; 
        }
    }

    const addGuestHandler = async() => {
        const btn = $('#btnSave');

        helper.StartProcessing(btn);

        try {
            let userPhoto = '';
            //Call upload API -->
            if(guestPhotoBlob){
                const res = await uploadPublicFile(guestPhotoBlob, 'User');
                userPhoto = res.fileName;  
            }else{
                if(guest.Photo) userPhoto = guest.Photo.split('/User/')[1];
            }
            const guestDetails = {
                ID:                    guest.ID || 0,
                Name:                  guest.Name,
                Email:                 guest.Email || '',
                Handicap:              guest.Handicap || '',
                IGUNumber:             guest.IGUNumber || '',
                Photo:                 userPhoto || '',
                CountryCode:           guest.CountryCode,
                Mobile:                guest.Mobile,
                Active:                guest.Active
            }
            await postGuest(guestDetails, otp, sessionId);
            helper.StopProcessing(btn);
            closeDrawer();
            fetchGuest();
            setOtp();
            if (guest.ID) {
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'Guest updated successfully!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,
                    animation: false,
                    customClass: {
                        popup: 'margin-20',
                    },
                });
            }else{
                swal.fire({
                    icon: 'success',
                    titleText: 'Guest added successfully!',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);
    }

    const openCropper = () =>{
        setCropperData({
            imageRatio  : { width : 200, height : 200},
            dataHandler : (val) =>  setGuestPhotoBlob(val),
            header : 'Upload Course Logo'
        })
    }
  

    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content">
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet kt-portlet--mobile">
                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                            <div className="kt-portlet__head-label">
                                <div className="row align-items-center margin-l10">
                                    <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                        <div className="kt-input-icon kt-input-icon--left">
                                            <input type="text" className="form-control clientSearch" placeholder="Search Guest..." id="generalSearch" />
                                            <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="kt-portlet__head-toolbar">
                                <div className="kt-portlet__head-wrapper">
                                    <div className="kt-portlet__head-actions">
                                        <button onClick={() => {setRightSideDetails(true),  setGuest({Active: 'Y', CountryCode : mobileCodeList[0].Code})}} type="button" className="btn btn-brand btn-icon-sm text-white d-inline"><i className="la la-plus"></i>New Guest</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__body kt-portlet__body--fit">
                            <div id="tblGuest" className="kt-datatable table-striped clickable"></div>
                            {rightSideDetails && <RightSideDetails title="Guest Details" onCloseDrawer={closeDrawer}>
                                <div className="kt-portlet">
                                    <div className="kt-portlet__body padding-t30 padding-b0">
                                        <form className="kt-form" id="add-guest-form">
                                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Name <span className="red">*</span></label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <input 
                                                            id="txtName" 
                                                            name="txtName" 
                                                            type="text" 
                                                            className="form-control" 
                                                            value={guest.Name || ''}
                                                            disabled={otpModal}
                                                            onChange={(event) => updateGuestDetails('Name', event.target.value)} 
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Email </label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <input 
                                                            id="txtEmail" 
                                                            name="txtEmail" 
                                                            type="text" 
                                                            className="form-control" 
                                                            value={guest.Email || ''}
                                                            disabled={otpModal}
                                                            onChange={(event) => updateGuestDetails('Email', event.target.value)} 
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group row ddlMobilePnl">
                                                    <label className="col-form-label col-lg-3">Mobile <span className="red">*</span></label>
                                                    <div className="col-xl-3 col-lg-4">
                                                        <Select
                                                            value={(mobileCodeList && mobileCodeList.length > 0 && guest.CountryCode) && {label: `${mobileCodeList.find(m => m.Code == guest.CountryCode).Name} ${mobileCodeList.find(m => m.Code == guest.CountryCode).Code}`, value: guest.CountryCode} || ''}
                                                            onChange={(event) => updateGuestDetails('CountryCode', event.value)}
                                                            name="ddlMobileCode"
                                                            id="ddlMobileCode"
                                                            isDisabled={otpModal}
                                                            className="ddlMobileCode"
                                                            options={mobileCodeList.map(c => ({
                                                                label: `${c.Name} (${c.Code})`,
                                                                value: c.Code
                                                            }))}
                                                        />
                                                    </div>
                                                    <div className="col-xl-3 col-lg-4">
                                                        <input 
                                                            id="txtMobile" 
                                                            name="txtMobile" 
                                                            type="text" 
                                                            disabled={otpModal}
                                                            className="form-control" 
                                                            value={guest.Mobile || ''}
                                                            onChange={(event) => updateGuestDetails('Mobile', event.target.value)} 
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Handicap</label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <input 
                                                            id="txtGuestHandicap" 
                                                            name="txtGuestHandicap" 
                                                            type="number" 
                                                            className="form-control"
                                                            disabled={otpModal}
                                                            value={guest.Handicap || ''}
                                                            onChange={(event) => updateGuestDetails('Handicap', event.target.value)} 
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">IGU Number </label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <input 
                                                            id="txtIGUNumber" 
                                                            name="txtIGUNumber" 
                                                            type="text" 
                                                            className="form-control" 
                                                            value={guest.IGUNumber || ''}
                                                            disabled={otpModal}
                                                            onChange={(event) => updateGuestDetails('IGUNumber', event.target.value)} 
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Photo</label>
                                                    <div className="col-sm-9">                                                        
                                                        {(!!guestPhotoBlob || guest.Photo) &&(<>
                                                            <div className="kt-avatar kt-avatar--outline kt-avatar--changed">
                                                                <div className="kt-avatar__holder border-none shadow-none" 
                                                                    style={{ backgroundImage : `url( ${guestPhotoBlob ? URL.createObjectURL(guestPhotoBlob) : guest.Photo && (guest.Photo) })` } }>    
                                                                </div>
                                                            </div>
                                                        </>) }

                                                        <p className="">
                                                            <button id="btn_Logo_Image" type="button" className="btn btn-secondary" onClick={openCropper} disabled={otpModal}>
                                                                {(!!guestPhotoBlob || guest.Photo) ? 'Change Photo' : 'Upload Photo'}
                                                            </button>
                                                        </p>
                                                        <p className="">(200 W x 200 H pixels)</p>                                                       
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Verified</label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <input 
                                                            id="txtVerified" 
                                                            name="txtVerified" 
                                                            type="text" 
                                                            className="form-control" 
                                                            disabled="disabled" 
                                                            value={guest.IsVerified || ''}
                                                            onChange={(event) => updateGuestDetails('IsVerified', event.target.value)} 
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Active</label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <span className="kt-switch kt-switch--icon">
                                                            <label>
                                                                <input 
                                                                    id="chkActive" 
                                                                    name="chkActive" 
                                                                    type="checkbox" 
                                                                    disabled={otpModal}
                                                                    defaultChecked={guest.Active == 'N' ? '' : 'checked'}
                                                                    onChange={(event) => updateGuestDetails('Active', event.target.checked ? 'Y' : 'N')} 
                                                                />
                                                                <span></span>
                                                            </label>
                                                        </span>
                                                    </div>
                                                </div>
                                                {otpModal && (
                                                    <div id="OTPHolder" className="form-group row">
                                                        <label className="col-form-label col-lg-3">Enter OTP <span className="red">*</span></label>
                                                        <div className="col-xl-3 col-lg-4">
                                                            <input 
                                                                id="txtOTP" 
                                                                name="txtOTP" 
                                                                type="text" 
                                                                className="form-control" 
                                                                onChange={({ target }) => setOtp(target.value)}
                                                                value={ otp || '' }
                                                            />
                                                        </div>

                                                        <button 
                                                            id="btnResendOTP" 
                                                            type="button" 
                                                            className="btn btn-brand d-inline-block margin-0 text-center"
                                                            onClick={otpHandler}
                                                        >
                                                            Resend OTP
                                                        </button>

                                                    </div>
                                                )}
                                            </div>
                                        </form>
                                    </div>
                                    <div className="kt-portlet__foot">
                                        <div className="kt-form__actions">
                                            <div className="row">
                                                <div className="col-lg-9 ml-lg-auto">
                                                    {otpModal || (guest.ID && otpModal)? (
                                                        <button id="btnSave" type="button" className="btn btn-brand d-inline-block" onClick={addGuestHandler}>Save</button>
                                                    ):(
                                                        <button id="btnGetOtp" type="button" className="btn btn-brand d-inline-block" onClick={otpHandler}>Get OTP</button>
                                                    )}
                                                    <button id="btnCancel" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}>Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </RightSideDetails>}
                        </div>
                    </div>
                </div>
            </div>
            {cropperData && (
                <ImageCropper
                    show           = { cropperData }
                    header         = { cropperData.header }   
                    imageRatio     = { cropperData.imageRatio }
                    dataHandler    = { cropperData.dataHandler }
                    onDismissModal = {() => setCropperData(null) }
                />
            )}
        </>
    )
}

export default Guest;