import { getAuthState } from "../../assets/scripts/login-util";
import { axiosPost, axiosGet, axiosDelete, axiosPatch } from "./axiosFunctions";

const getSportReportByTypeUrl                                  = (fromDate, toDate, type) => `/api/admin/dashboard/getSportReportByType/${fromDate}/${toDate}/${type}`;

export const getSportReportByType = async (fromDate, toDate, type) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getSportReportByTypeUrl(fromDate, toDate, type),
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};