import { useState } from "react";
import RightSideDetails from "../global/rightSideDetails";
import { useEffect } from "react";
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import ImageCropper from "../global/imageCropper";
import { uploadPublicFile } from "../api/fileAPI";
import { getSportMasterAll, postSportsMaster } from "../api/sportsAPI";
import Select from 'react-select';


const SportsMaster = (props) => {

    
    const [sports,              setSports]              = useState({ActiveAdmin: 'Y', ActiveUser: 'Y'});
    const [sportsList,          setSportList]           = useState([]);
    const [isInit,              setIsInit]              = useState(true);
    const [rightSideDetails,    setRightSideDetails]    = useState(false);
    const [BookingTypeOption,   setBookingTypeOption]   = useState([{ value:  'Session', label: 'Session'}, { value:  'Slot', label: 'Slot'},]);

    //<--- Cropper -->
    const [sportsLogoBlob,  setSportsLogoBlob] = useState(null);
    const [cropperData,     setCropperData]   = useState(null);


    useEffect(() => {
        props.title("Sports Master", "sports-master")
        fetchSportsMaster();
    }, []);

    useEffect(() => {       
        if (!isInit) fillDataTable();
    }, [sportsList]);
    
    useEffect(() => {  
        rightSideDetails ? Config.Core.OpenDrawer() : setSports({ActiveAdmin: 'Y', ActiveUser: 'Y'}), setSportsLogoBlob(null);
    }, [rightSideDetails]);

    const fetchSportsMaster = async() => {
        try {
            const sportMasters =  await getSportMasterAll();
            isInit ? setIsInit(false) : $('#tblSports').KTDatatable().destroy(); 
            setSportList(sportMasters)
        } catch (err) {
            swal.fire({
                icon      : 'error',
                titleText : err.message
            })  
        }
    }

    function fillDataTable() {

        const dataTable = $('#tblSports').KTDatatable({
            data: {
                saveState: false,
                source: sportsList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'Title',
                    title: 'Sport',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                <div class="kt-user-card-v2__pic">
                                    ${(row.Logo && row.Logo != '') ? `<img src="${row.Logo}" alt="event-thumbail" >` : ` <div class="kt-badge kt-badge--xl kt-badge--info">  ${row.Name.substring(0, 1)}</div>`}
                                </div>
                                <div class="kt-user-card-v2__details text-clip">
                                    <span class="kt-user-card-v2__name text-clip"> ${row.Name} </span> 
                                    <span class="kt-user-card-v2__desc text-clip"> ${row.BookingType} </span>
                                </div>
                            </div>`
                    }

                },
                {
                    field: 'ActiveAdmin',
                    title: 'Active Admin',
                    template: function (row) {
                        if (row.ActiveAdmin == 'Y') {
                            return '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                        } else {
                            return '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10"> No </span>'
                        }
                    },
                    width: 100,
                },
                {
                    field: 'ActiveUser',
                    title: 'Active User',
                    template: function (row) {
                        if (row.ActiveUser == 'Y') {
                            return '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                        } else {
                            return '<span class="kt-badge kt-badge--grey kt-badge--pill padding-lr20 padding-tb10"> No </span>'
                        }
                    },
                    width: 100,
                },
            ]
        });

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblSports .kt-datatable__table').addClass('overflow-x-auto');

        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);
            if (dataTable.dataSet[index]) {
                setSports(dataTable.dataSet[index]);  
                fetchSportsMaster(dataTable.dataSet[index].ID);
                setRightSideDetails(true); 
            }    
        });
    }
  
    const updateSportsDetails = (field, value) => {   
        setSports((d) => {
            return { ...d, [field]: value };
        });
    };

    const addSportHandler = async() => {

        const btn = $('#btnSave');
        var form = $('#add-game-form');

        form.validate({
            rules: {
                txtName: {
                    required: true
                },
                ddlBookingType: {
                    required: true
                },
                txtMarkerDisplayName: {
                    required: true
                },
                txtMaximumCapacity: {
                    required: true
                },
                txtConcurrentBookingAdmin: {
                    required: true
                },
                txtMaximumBookingAdmin: {
                    required: true
                },
                txtConcurrentBookingUser: {
                    required: true
                },
                txtMaximumBookingUser: {
                    required: true
                },
                txtMinimumMemberAdmin: {
                    required: true
                },
                txtMinimumMemberUser: {
                    required: true
                },
                txtMaximumMemberAdmin: {
                    required: true
                },
                txtMaximumMemberUser: {
                    required: true
                },
                txtMinimumGuestAdmin: {
                    required: true
                },
                txtMinimumGuestUser: {
                    required: true
                },
                txtMaximumGuestAdmin: {
                    required: true
                },
                txtMaximumGuestUser: {
                    required: true
                },
                txtMinimumMarkerAdmin: {
                    required: true
                },
                txtMinimumMarkerUser: {
                    required: true
                },
                txtMaximumMarkerAdmin: {
                    required: true
                },
                txtMaximumMarkerUser: {
                    required: true
                },
                txtMinimumPlayerAdmin: {
                    required: true
                },
                txtMaximumPlayerAdmin: {
                    required: true
                },
                txtMinimumPlayerUser: {
                    required: true
                },
                txtMaximumPlayerUser: {
                    required: true
                }            
            },
            messages: {
                txtName: {
                    required: "Please enter name"
                },
                ddlBookingType: {
                    required: "Please select booking type"
                },
                txtMarkerDisplayName: {
                    required: "Please enter marker display name"
                },
                txtMaximumCapacity: {
                    required: "Please enter capacity"
                },
                txtConcurrentBookingAdmin: {
                    required: "Please enter concurrent booking"
                },
                txtMaximumBookingAdmin: {
                    required: "Please enter maximum booking"
                },
                txtConcurrentBookingUser: {
                    required: "Please enter concurrent booking"
                },
                txtMaximumBookingUser: {
                    required: "Please enter maximum booking"
                },
                txtMinimumMemberAdmin: {
                    required: "Please enter minimum member"
                },
                txtMinimumMemberUser: {
                    required: "Please enter minimum member"
                },
                txtMaximumMemberAdmin: {
                    required: "Please enter maximum member"
                },
                txtMaximumMemberUser: {
                    required: "Please enter maximum member"
                },
                txtMinimumGuestAdmin: {
                    required: "Please enter minimum guest"
                },
                txtMinimumGuestUser: {
                    required: "Please enter minimum guest"
                },
                txtMaximumGuestAdmin: {
                    required: "Please enter maximum guest"
                },
                txtMaximumGuestUser: {
                    required: "Please enter maximum guest"
                },
                txtMinimumMarkerAdmin: {
                    required: "Please enter minimum marker"
                },
                txtMinimumMarkerUser: {
                    required: "Please enter minimum marker"
                },
                txtMaximumMarkerAdmin: {
                    required: "Please enter maximum marker"
                },
                txtMaximumMarkerUser: {
                    required: "Please enter maximum marker"
                },
                txtMinimumPlayerAdmin: {
                    required: "Please enter minimum player"
                },
                txtMaximumPlayerAdmin: {
                    required: "Please enter maximum player"
                },
                txtMinimumPlayerUser: {
                    required: "Please enter maximum player"
                },
                txtMaximumPlayerUser: {
                    required: "Please enter maximum player"
                }
            }
        });

        if (!form.valid()) return;
        helper.StartProcessing(btn);

        try {
            
            if((sports.ID != 1 && sports.ID != 2) && (sports.MinimumPlayerAdmin < Number(sports.MinimumMemberAdmin) + Number(sports.MinimumGuestAdmin) + Number(sports.MinimumMarkerAdmin) || sports.MinimumPlayerUser < Number(sports.MinimumMemberUser) + Number(sports.MinimumGuestUser) + Number(sports.MinimumMarkerUser))){
                swal.fire({
                    icon                  : 'warning',
                    titleText             : `Total player count cannot exceed minimum player count of ${sports.MinimumPlayerAdmin < Number(sports.MinimumMemberAdmin) + Number(sports.MinimumGuestAdmin) + Number(sports.MinimumMarkerAdmin) ? sports.MinimumPlayerAdmin : sports.MinimumPlayerUser}.`,
                    buttonsStyling        : false,  
                    confirmButtonClass    : 'btn btn-brand',   
                });
                helper.StopProcessing(btn);
                return 
            }

            if((sports.ID != 1 && sports.ID != 2) && (sports.MaximumPlayerAdmin > Number(sports.MaximumMemberAdmin) + Number(sports.MaximumGuestAdmin) + Number(sports.MaximumMarkerAdmin) || sports.MaximumPlayerUser > Number(sports.MaximumMemberUser) + Number(sports.MaximumGuestUser) + Number(sports.MaximumMarkerUser))){
                swal.fire({
                    icon                  : 'warning',
                    titleText             : `Total player count cannot subceed maximum player count of ${(sports.MaximumPlayerAdmin > Number(sports.MaximumMemberAdmin) + Number(sports.MaximumGuestAdmin) + Number(sports.MaximumMarkerAdmin)) ? sports.MaximumPlayerAdmin : sports.MaximumPlayerUser }.`,
                    buttonsStyling        : false,  
                    confirmButtonClass    : 'btn btn-brand',   
                });
                helper.StopProcessing(btn);
                return 
            }
            
            let sportsLogo = '';
            //Call upload API -->
            if(sportsLogoBlob){
                const res = await uploadPublicFile(sportsLogoBlob, 'Content');
                sportsLogo = res.fileName;            
            }else{
                if(sports.Logo) sportsLogo = sports.Logo.split('/Content/')[1];
            }
            const sportsDetails = {
                ID:                         sports.ID || 0,
                Name:                       sports.Name,
                Logo:                       sportsLogo || '',
                BookingType:                sports.BookingType,
                MaximumCapacity:            sports.MaximumCapacity || 1,
                MarkerDisplayName:          sports.MarkerDisplayName,
                ConcurrentBookingAdmin:     sports.ConcurrentBookingAdmin || 0, 
                ConcurrentBookingUser:      sports.ConcurrentBookingUser || 0, 
                MaximumBookingAdmin:        sports.MaximumBookingAdmin || 0, 
                MaximumBookingUser:         sports.MaximumBookingUser || 0, 
                MinimumMemberAdmin:         sports.MinimumMemberAdmin || 0, 
                MinimumMemberUser:          sports.MinimumMemberUser || 0, 
                MaximumMemberAdmin:         sports.MaximumMemberAdmin || 0, 
                MaximumMemberUser:          sports.MaximumMemberUser || 0, 
                MinimumGuestAdmin:          sports.MinimumGuestAdmin || 0, 
                MinimumGuestUser:           sports.MinimumGuestUser || 0, 
                MaximumGuestAdmin:          sports.MaximumGuestAdmin || 0, 
                MaximumGuestUser:           sports.MaximumGuestUser || 0, 
                MinimumMarkerAdmin:         sports.MinimumMarkerAdmin || 0, 
                MinimumMarkerUser:          sports.MinimumMarkerUser || 0, 
                MaximumMarkerAdmin:         sports.MaximumMarkerAdmin || 0, 
                MaximumMarkerUser:          sports.MaximumMarkerUser || 0, 
                MinimumPlayerAdmin:         sports.MinimumPlayerAdmin || 0, 
                MinimumPlayerUser:          sports.MinimumPlayerUser || 0, 
                MaximumPlayerUser:          sports.MaximumPlayerUser || 0, 
                MaximumPlayerAdmin:         sports.MaximumPlayerAdmin || 0, 
                ActiveAdmin:                sports.ActiveAdmin, 
                ActiveUser:                 sports.ActiveUser  
            };       

            await postSportsMaster(sportsDetails);
            helper.StopProcessing(btn);
            closeDrawer();
            fetchSportsMaster();
            if (sports.ID) {
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'Game updated successfully!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,
                    animation: false,
                    customClass: {
                        popup: 'margin-20',
                    },
                });
            }else{
                swal.fire({
                    icon: 'success',
                    titleText: 'Game added successfully!',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {          
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);        
    }

    const openCropper = () =>{
        setCropperData({
            imageRatio  : { width : 200, height : 200},
            dataHandler : (val) =>  setSportsLogoBlob(val),
            header : 'Upload Sports Logo'
        })
    }
  
    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor  padding-tb0" id="kt_content"  >
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet ">
                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                            <div className="kt-portlet__head-label">
                                <div className="row align-items-center margin-l10">
                                    <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                        <div className="kt-input-icon kt-input-icon--left">
                                            <input type="text" className="form-control clientSearch" placeholder="Search Sport..." id="generalSearch" />
                                            <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="kt-portlet__head-toolbar">
                                <div className="kt-portlet__head-wrapper">
                                    <div className="kt-portlet__head-actions">
                                        <button onClick={() => setRightSideDetails(true)}  type="button" className="btn btn-brand btn-icon-sm text-white d-inline"><i className="la la-plus"></i>New Sport</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__body kt-portlet__body--fit">
                            <div id="tblSports" className="kt-datatable table-striped clickable"></div>
                            {rightSideDetails && <RightSideDetails title="Game Details" onCloseDrawer={closeDrawer}>
                                <div className="kt-portlet">
                                    <div className="kt-portlet__body padding-t30 padding-b0">
                                        <form className="kt-form" id="add-game-form">
                                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">                                       
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">Name <span className="red">*</span></label>
                                                    <div className="col-xl-6 col-lg-8">
                                                        <input 
                                                            id="txtName" 
                                                            name="txtName" 
                                                            type="text" 
                                                            className="form-control"
                                                            value={sports.Name || ''}
                                                            onChange={(event) => updateSportsDetails('Name', event.target.value)}  
                                                        />
                                                    </div>
                                                </div>   
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3"> Logo </label>
                                                    <div className="col-sm-9">                                                        
                                                        {(!!sportsLogoBlob || sports.Logo) &&(<>
                                                            <div className="kt-avatar kt-avatar--outline kt-avatar--changed">
                                                                <div className="kt-avatar__holder border-none shadow-none" 
                                                                    style={{ backgroundImage : `url( ${sportsLogoBlob ? URL.createObjectURL(sportsLogoBlob) : sports.Logo && (sports.Logo) })` } }>    
                                                                </div>
                                                            </div>
                                                        </>) }

                                                        <p className="">
                                                            <button id="btn_Logo_Image" type="button" className="btn btn-secondary" onClick={openCropper}>
                                                            {(!!sportsLogoBlob || sports.Logo) ? 'Change Logo' : 'Upload Logo'}
                                                            </button>
                                                        </p>
                                                        <p className="">(200 W x 200 H pixels)</p>                                                       
                                                    </div>
                                                </div>
                                                {sports.ID != 1 && sports.ID != 2 && (
                                                    <>
                                                        <div className="form-group row">
                                                            <label className="col-form-label col-lg-3">Booking Type <span className="red">*</span></label>
                                                            <div className="col-xl-6 col-lg-8">
                                                                <Select 
                                                                    id="ddlBookingType" 
                                                                    name="ddlBookingType"
                                                                    className="multi-custom"
                                                                    value={sports && BookingTypeOption.find((m) => m.value == sports.BookingType) || ''}
                                                                    onChange={(event) => updateSportsDetails('BookingType', event.value)}
                                                                    options={BookingTypeOption}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-form-label col-lg-3"> Marker Display Name <span className="red">*</span></label>
                                                            <div className="col-xl-6 col-lg-8">
                                                                <input 
                                                                    id="txtMarkerDisplayName" 
                                                                    name="txtMarkerDisplayName" 
                                                                    type="text" 
                                                                    className="form-control"
                                                                    value={sports.MarkerDisplayName || ''}
                                                                    onChange={(event) => updateSportsDetails('MarkerDisplayName', event.target.value)}  
                                                                />
                                                            </div>
                                                        </div>
                                                        {sports.BookingType == "Session" && (
                                                            <div className="form-group row">
                                                                <label className="col-form-label col-lg-3"> Maximum Capacity <span className="red">*</span></label>
                                                                <div className="col-xl-6 col-lg-8">
                                                                    <input id="txtMaximumCapacity" name="txtMaximumCapacity" type="number" className="form-control"
                                                                        defaultValue = {sports.MaximumCapacity  || 1}
                                                                        onChange={({target}) => updateSportsDetails("MaximumCapacity", target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className="row padding-t20">
                                                            {/* //admin */}
                                                            <div className="col-6 form-group" >
                                                                <div className="kt-portlet" style={{boxShadow: '0px 0px 13px 0px rgb(82 63 105 / 11%)'}}>
                                                                    <div className="kt-portlet__head" style={{paddingLeft: '50%'}}>
                                                                        <div className="kt-portlet__head-label">
                                                                            <h3 className="kt-portlet__head-title">
                                                                                Admin
                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                    <div className="kt-portlet__body">
                                                                        <div className="form-group row">
                                                                            <label className="col-form-label col-lg-4"> Concurrent Booking per Player<span className="red">*</span></label>
                                                                            <div className="col-xl-2 col-lg-2">
                                                                                <input id="txtConcurrentBookingAdmin" name="txtConcurrentBookingAdmin" type="number" className="form-control"
                                                                                    value = {sports.ConcurrentBookingAdmin == 0 ? 0 : sports.ConcurrentBookingAdmin   || ''}
                                                                                    onChange={({target}) => updateSportsDetails("ConcurrentBookingAdmin", target.value)}
                                                                                />
                                                                            </div>
                                                                            <label  className="col-form-label col-lg-4"> Maximum Booking per day<span className="red">*</span></label>
                                                                            <div className="col-xl-2 col-lg-2">
                                                                                <input id="txtMaximumBookingAdmin" name="txtMaximumBookingAdmin" type="number" className="form-control"
                                                                                    value = {sports.MaximumBookingAdmin == 0 ? 0 : sports.MaximumBookingAdmin || ''}
                                                                                    onChange={({target}) => updateSportsDetails("MaximumBookingAdmin", target.value)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row">
                                                                            <label className="col-form-label col-lg-4"> Minimum Player <span className="red">*</span></label>
                                                                                <div className="col-xl-2 col-lg-2">
                                                                                    <input id="txtMinimumPlayerAdmin" name="txtMinimumPlayerAdmin" type="number" className="form-control"
                                                                                        value = {sports.MinimumPlayerAdmin == 0 ? 0 : sports.MinimumPlayerAdmin || ''}
                                                                                        onChange={({target}) => updateSportsDetails("MinimumPlayerAdmin", target.value)}
                                                                                    />
                                                                                </div>
                                                                            <label className="col-form-label col-lg-4"> Maximum Player <span className="red">*</span></label>
                                                                            <div className="col-xl-2 col-lg-2">
                                                                                <input id="txtMaximumPlayerAdmin" name="txtMaximumPlayerAdmin" type="number" className="form-control"
                                                                                    value = {sports.MaximumPlayerAdmin == 0 ? 0 : sports.MaximumPlayerAdmin || ''}
                                                                                    onChange={({target}) => updateSportsDetails("MaximumPlayerAdmin", target.value)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row">
                                                                            <label className="col-form-label col-lg-4"> Minimum Member <span className="red">*</span></label>
                                                                            <div className="col-xl-2 col-lg-2">
                                                                                <input id="txtMinimumMemberAdmin" name="txtMinimumMemberAdmin" type="number" className="form-control"
                                                                                    value = {sports.MinimumMemberAdmin == 0 ? 0 : sports.MinimumMemberAdmin || ''}
                                                                                    onChange={({target}) => updateSportsDetails("MinimumMemberAdmin", target.value)}
                                                                                />
                                                                            </div>
                                                                            <label className="col-form-label col-lg-4"> Maximum Member <span className="red">*</span></label>
                                                                            <div className="col-xl-2 col-lg-2">
                                                                                <input id="txtMaximumMemberAdmin" name="txtMaximumMemberAdmin" type="number" className="form-control"
                                                                                    value = {sports.MaximumMemberAdmin == 0 ? 0 : sports.MaximumMemberAdmin || ''}
                                                                                    onChange={({target}) => updateSportsDetails("MaximumMemberAdmin", target.value)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row">
                                                                            <label className="col-form-label col-lg-4"> Minimum Guest <span className="red">*</span></label>
                                                                            <div className="col-xl-2 col-lg-2">
                                                                                <input id="txtMinimumGuestAdmin" name="txtMinimumGuestAdmin" type="number" className="form-control"
                                                                                    value = {sports.MinimumGuestAdmin == 0 ? 0 : sports.MinimumGuestAdmin || ''}
                                                                                    onChange={({target}) => updateSportsDetails("MinimumGuestAdmin", target.value)}
                                                                                />
                                                                            </div>
                                                                            <label className="col-form-label col-lg-4"> Maximum Guest <span className="red">*</span></label>
                                                                            <div className="col-xl-2 col-lg-2">
                                                                                <input id="txtMaximumGuestAdmin" name="txtMaximumGuestAdmin" type="number" className="form-control"
                                                                                    value = {sports.MaximumGuestAdmin == 0 ? 0 : sports.MaximumGuestAdmin || ''}
                                                                                    onChange={({target}) => updateSportsDetails("MaximumGuestAdmin", target.value)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row">
                                                                            <label className="col-form-label col-lg-4"> Minimum Marker <span className="red">*</span></label>
                                                                            <div className="col-xl-2 col-lg-2">
                                                                                <input id="txtMinimumMarkerAdmin" name="txtMinimumMarkerAdmin" type="number" className="form-control"
                                                                                    value = {sports.MinimumMarkerAdmin == 0 ? 0 : sports.MinimumMarkerAdmin || ''}
                                                                                    onChange={({target}) => updateSportsDetails("MinimumMarkerAdmin", target.value)}
                                                                                />
                                                                            </div>
                                                                            <label className="col-form-label col-lg-4"> Maximum Marker <span className="red">*</span></label>
                                                                            <div className="col-xl-2 col-lg-2">
                                                                                <input id="txtMaximumMarkerAdmin" name="txtMaximumMarkerAdmin" type="number" className="form-control"
                                                                                    value = {sports.MaximumMarkerAdmin == 0 ? 0 : sports.MaximumMarkerAdmin || ''}
                                                                                    onChange={({target}) => updateSportsDetails("MaximumMarkerAdmin", target.value)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row">
                                                                            <label className="col-form-label col-lg-6">Active</label>
                                                                            <div className="col-xl-6 col-lg-6">
                                                                                <span className="kt-switch kt-switch--icon">
                                                                                    <label>
                                                                                        <input 
                                                                                            id="chkActive" 
                                                                                            name="chkActive" 
                                                                                            type="checkbox" 
                                                                                            defaultChecked={sports.ActiveAdmin == 'N' ? '' : 'checked'}
                                                                                            onChange={(event) => updateSportsDetails('ActiveAdmin', event.target.checked ? 'Y' : 'N')} 
                                                                                        />
                                                                                        <span></span>
                                                                                    </label>
                                                                                </span>
                                                                            </div>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* //user */}
                                                            <div className="col-6 form-group" >
                                                                <div className="kt-portlet" style={{boxShadow: '0px 0px 13px 0px rgb(82 63 105 / 11%)'}}>
                                                                    <div className="kt-portlet__head" style={{paddingLeft: '50%'}}>
                                                                        <div className="kt-portlet__head-label">
                                                                            <h3 className="kt-portlet__head-title">
                                                                                User
                                                                            </h3>
                                                                        </div>
                                                                    </div>

                                                                    <div className="kt-portlet__body">
                                                                        <div className="form-group row">
                                                                            <label className="col-form-label col-lg-4"> Concurrent Booking per Player<span className="red">*</span></label>
                                                                            <div className="col-xl-2 col-lg-2">
                                                                                <input id="txtConcurrentBookingUser" name="txtConcurrentBookingUser" type="number" className="form-control"
                                                                                    value = {sports.ConcurrentBookingUser == 0 ? 0 : sports.ConcurrentBookingUser || ''}
                                                                                    onChange={({target}) => updateSportsDetails("ConcurrentBookingUser", target.value)}
                                                                                />
                                                                            </div>
                                                                            <label className="col-form-label col-lg-4"> Maximum Booking per day <span className="red">*</span></label>
                                                                            <div className="col-xl-2 col-lg-2">
                                                                                <input id="txtMaximumBookingUser" name="txtMaximumBookingUser" type="number" className="form-control"
                                                                                    value = {sports.MaximumBookingUser == 0 ? 0 : sports.MaximumBookingUser || ''}
                                                                                    onChange={({target}) => updateSportsDetails("MaximumBookingUser", target.value)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row">
                                                                            <label className="col-form-label col-lg-4"> Minimum Player <span className="red">*</span></label>
                                                                                <div className="col-xl-2 col-lg-2">
                                                                                    <input id="txtMinimumPlayerUser" name="txtMinimumPlayerUser" type="number" className="form-control"
                                                                                        value = {sports.MinimumPlayerUser == 0 ? 0 : sports.MinimumPlayerUser || ''}
                                                                                        onChange={({target}) => updateSportsDetails("MinimumPlayerUser", target.value)}
                                                                                    />
                                                                                </div>
                                                                            <label className="col-form-label col-lg-4"> Maximum Player <span className="red">*</span></label>
                                                                            <div className="col-xl-2 col-lg-2">
                                                                                <input id="txtMaximumPlayerUser" name="txtMaximumPlayerUser" type="number" className="form-control"
                                                                                    value = {sports.MaximumPlayerUser == 0 ? 0 : sports.MaximumPlayerUser || ''}
                                                                                    onChange={({target}) => updateSportsDetails("MaximumPlayerUser", target.value)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row">
                                                                            <label className="col-form-label col-lg-4"> Minimum Member <span className="red">*</span></label>
                                                                            <div className="col-xl-2 col-lg-2">
                                                                                <input id="txtMinimumMemberUser" name="txtMinimumMemberUser" type="number" className="form-control"
                                                                                    value = {sports.MinimumMemberUser == 0 ? 0 : sports.MinimumMemberUser || ''}
                                                                                    onChange={({target}) => updateSportsDetails("MinimumMemberUser", target.value)}
                                                                                />
                                                                            </div>
                                                                            <label className="col-form-label col-lg-4"> Maximum Member <span className="red">*</span></label>
                                                                            <div className="col-xl-2 col-lg-2">
                                                                                <input id="txtMaximumMemberUser" name="txtMaximumMemberUser" type="number" className="form-control"
                                                                                    value = {sports.MaximumMemberUser == 0 ? 0 : sports.MaximumMemberUser || ''}
                                                                                    onChange={({target}) => updateSportsDetails("MaximumMemberUser", target.value)}
                                                                                />

                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row">
                                                                            <label className="col-form-label col-lg-4"> Minimum Guest <span className="red">*</span></label>
                                                                            <div className="col-xl-2 col-lg-2">
                                                                                <input id="txtMinimumGuestUser" name="txtMinimumGuestUser" type="number" className="form-control"
                                                                                    value = {sports.MinimumGuestUser == 0 ? 0 : sports.MinimumGuestUser || ''}
                                                                                    onChange={({target}) => updateSportsDetails("MinimumGuestUser", target.value)}
                                                                                />
                                                                            </div>
                                                                            <label className="col-form-label col-lg-4"> Maximum Guest <span className="red">*</span></label>
                                                                            <div className="col-xl-2 col-lg-2">
                                                                                <input id="txtMaximumGuestUser" name="txtMaximumGuestUser" type="number" className="form-control"
                                                                                    value = {sports.MaximumGuestUser == 0 ? 0 : sports.MaximumGuestUser || ''}
                                                                                    onChange={({target}) => updateSportsDetails("MaximumGuestUser", target.value)}
                                                                                />

                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row">
                                                                            <label className="col-form-label col-lg-4"> Minimum Marker <span className="red">*</span></label>
                                                                            <div className="col-xl-2 col-lg-2">
                                                                                <input id="txtMinimumMarkerUser" name="txtMinimumMarkerUser" type="number" className="form-control"
                                                                                    value = {sports.MinimumMarkerUser == 0 ? 0 : sports.MinimumMarkerUser || ''}
                                                                                    onChange={({target}) => updateSportsDetails("MinimumMarkerUser", target.value)}
                                                                                />

                                                                            </div>
                                                                            <label className="col-form-label col-lg-4"> Maximum Marker <span className="red">*</span></label>
                                                                            <div className="col-xl-2 col-lg-2">
                                                                                <input id="txtMaximumMarkerUser" name="txtMaximumMarkerUser" type="number" className="form-control"
                                                                                    value = {sports.MaximumMarkerUser == 0 ? 0 : sports.MaximumMarkerUser || ''}
                                                                                    onChange={({target}) => updateSportsDetails("MaximumMarkerUser", target.value)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row">
                                                                            <label className="col-form-label col-lg-6">Active</label>
                                                                            <div className="col-xl-6 col-lg-6">
                                                                                <span className="kt-switch kt-switch--icon">
                                                                                    <label>
                                                                                        <input 
                                                                                            id="chkActive" 
                                                                                            name="chkActive" 
                                                                                            type="checkbox" 
                                                                                            defaultChecked={sports.ActiveUser == 'N' ? '' : 'checked'}
                                                                                            onChange={(event) => updateSportsDetails('ActiveUser', event.target.checked ? 'Y' : 'N')} 
                                                                                        />
                                                                                        <span></span>
                                                                                    </label>
                                                                                </span>
                                                                            </div>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                <div className="kt-portlet__foot">
                                                    <div className="kt-form__actions">
                                                        <div className="row">
                                                            <div className="col-lg-7 ml-lg-auto">
                                                                <button id="btnSave" type="button" className="btn btn-brand d-inline-block" onClick={addSportHandler}>Save</button>
                                                                <button id="btnCancel" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}>Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </RightSideDetails>}
                        </div>
                    </div>
                </div>           
            </div>
            {cropperData && (
                <ImageCropper
                    show           = { cropperData }
                    header         = { cropperData.header }   
                    imageRatio     = { cropperData.imageRatio }
                    dataHandler    = { cropperData.dataHandler }
                    onDismissModal = {() => setCropperData(null) }
                />
            )}
        </>
    )
}

export default SportsMaster;