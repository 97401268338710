import { useState } from "react";
import UserDetails from "./userDetails";

const Member = (props) =>{

    return(
        <>
            <div className="row">
                <div className="col-sm-12">
                    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0">
                        <table className="kt-datatable__table">
                            <thead
                                className="kt-datatable__head"
                                style={{ display: 'block' }}>

                                <tr className="kt-datatable__row block-row">
                                    <th className="kt-datatable__cell text-clip" width="20%">
                                        <span className="padding-l10">Name</span>
                                    </th>
                                    <th className="kt-datatable__cell text-clip" width="20%">
                                        <span className="padding-l10">Email</span>
                                    </th>
                                    <th className="kt-datatable__cell text-clip" width="20%">
                                        <span>Mobile</span>
                                    </th>
                                    <th className="kt-datatable__cell text-clip" width="15%">
                                        <span>App User</span>
                                    </th>
                                    <th className="kt-datatable__cell text-clip" width="15%">
                                        <span>Active</span>
                                    </th>
                                    <th className="kt-datatable__cell--center kt-datatable__cell text-clip" width="10%">
                                    </th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded">
                        <table className="kt-datatable__table table-striped">
                            <tbody className="kt-datatable__body" style={{ display: 'block' }} >
                                {props.members.map((m, i) => (
                                    <tr key={i} className="kt-datatable__row block-row">
                                        <td  width="20%" className="padding-l20 kt-datatable__cell text-clip"  onClick={() => props.setShowUserDetails(m)}>
                                            <div className="kt-user-card-v2">
                                                <div className="kt-user-card-v2__pic">
                                                    {m.Photo && m.Photo != '' ? 
                                                    <img src={m.Photo} alt="user-profile"  height={50} width={50} /> : 
                                                    <div className={`kt-badge kt-badge--xl kt-badge--${m.Category === 'Organization' ? 'success' : 'info'} `}> {m.FirstName.substring(0, 1)}</div>}
                                                </div>
                                                <div className="kt-user-card-v2__details text-clip">
                                                    <span className="kt-user-card-v2__name text-clip"> {`${m.FirstName} ${m.MiddleName || ''} ${m.LastName}`} </span> <span className="kt-user-card-v2__desc text-clip"> {m.MembershipNo} </span>
                                                </div>
                                            </div>
                                        </td>
                                        <td width="20%" className="padding-l20 kt-datatable__cell text-clip"  onClick={() => props.setShowUserDetails(m)}>
                                            <span>
                                                {m.Email}
                                            </span>
                                        </td>
                                        <td width="20%" className="padding-l20 kt-datatable__cell text-clip"  onClick={() => props.setShowUserDetails(m)}>
                                            <span>{m.Mobile || ''}</span>
                                        </td>
                                        <td width="15%" className="  kt-datatable__cell text-clip"  onClick={() => props.setShowUserDetails(m)}>
                                           <span><span className={`kt-badge kt-badge--${m.IsAppUser == 'Y' ? 'success' : 'grey'} kt-badge--pill  padding-lr20 padding-tb10`}>{m.IsAppUser == 'Y' ? 'Yes' : 'No'}</span></span> 
                                        </td>
                                        <td width="15%" className="padding-l20 kt-datatable__cell text-clip">
                                            <span><span className={`kt-badge kt-badge--${m.Active == 'Y' ? 'success' : 'grey'} kt-badge--pill padding-lr20 padding-tb10`}>{m.Active == 'Y' ? 'Yes' : 'No'}</span></span>
                                        </td>
                                        <td width="10%" className="text-right kt-datatable__cell text-clip">
                                            <button
                                                type="button"
                                                className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10"
                                                onClick={() => {props.updateUserHandler(), props.setMemberDetails(m)}}
                                                >
                                                <i className="flaticon2-edit"></i>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
} 

export default Member;